import {observer} from "mobx-react";
import {Card, Form, Input, Row, Spin, Typography, List} from "antd";
import React from "react";
import styles from './index.module.css';
import {FieldData} from "rc-field-form/lib/interface";
import Title from "antd/lib/typography/Title";
import FilePdfOutlined from "@ant-design/icons/lib/icons/FilePdfOutlined";
import {get} from "lodash";
import {policyViewState} from "../../../../state/policy/policy-view.state";

export const PolicyForm = observer(() => {
  const policy = [
    {label: 'Номер полиса', name: 'number', styles: styles.inputWrite},
    {label: 'Тип полиса', name: 'type', styles: styles.inputShort},
    {label: 'Дата начала действия полиса', name: 'begins_at', styles: styles.inputShort},
    {label: 'Дата окончания действия полиса', name: 'ends_at', styles: styles.inputShort},
    {label: 'Статус полиса', name: 'active', styles: styles.inputShort},
    {label: 'Стоимость, руб', name: 'price', styles: styles.inputShort},
    {label: 'Статус оплаты', name: 'payment.status', styles: styles.inputShort},
    {label: 'Дата оплаты', name: 'payment.created_at', styles: styles.inputShort},
  ];
  const insuredVehicle = [
    {label: 'Тип ТС', name: 'vehicle.type.name', styles: styles.inputShort},
    {label: 'Марка ТС', name: 'vehicle.brand.name', styles: styles.inputShort},
    {label: 'Модель ТС', name: 'vehicle.model.name', styles: styles.inputShort},
    {label: 'Цена ТС, руб.', name: 'vehicle.price', styles: styles.inputShort},
    {label: 'Год приобретения', name: 'vehicle.acquisition_year', styles: styles.inputShort},
  ]
  const insuredPerson = [
    {label: 'Номер телефона', name: 'user.phone_number', styles: styles.inputShort},
    {label: 'Имя ЗЛ', name: 'user.firstname', styles: styles.inputWrite},
    {label: 'Фамилия ЗЛ', name: 'user.lastname', styles: styles.inputWrite},
    {label: 'Отчество ЗЛ', name: 'user.patronymic', styles: styles.inputWrite},
    {label: 'Дата рождения', name: 'passport.date_of_birth', styles: styles.inputShort},
    {label: 'Пол', name: 'user.gender', styles: styles.inputShort},
    {label: 'Место рождения', name: 'passport.place_of_birth', styles: styles.inputWrite},
    {label: 'Серия и номер паспорта', name: 'passport.series_number', styles: styles.inputShort},
    {label: 'Паспорт выдан', name: 'passport.issuer', styles: styles.inputWrite},
    {label: 'Дата выдачи', name: 'passport.issued_at', styles: styles.inputShort},
    {label: 'Код подразделения', name: 'passport.department_code', styles: styles.inputShort},
    {label: 'Место регистрации', name: 'passport.registration', styles: styles.inputWrite},
    {label: 'Email', name: 'user.email', styles: styles.inputShort},
  ]

  if (!policyViewState.policy) {
    return (
      <Spin/>
    );
  }

  const fields: FieldData[] = [
    'number', 'type', 'price', 'begins_at', 'ends_at', 'active', 'payment.status', 'payment.created_at',
    'user.lastname', 'user.firstname', 'user.patronymic', 'user.email', 'user.phone_number',
    'user.gender', 'passport.series', 'passport.series_number', 'passport.number', 'passport.date_of_birth', 'passport.place_of_birth',
    'passport.issuer', 'passport.issued_at', 'passport.department_code', 'passport.registration', 'vehicle.type.name',
    'vehicle.model.name', 'vehicle.brand.name', 'vehicle.price', 'vehicle.acquisition_year'
  ].map(e => ({
    name: e,
    value: get(policyViewState.policy, e)
  }));
  return (
    <>
      <Form
        style={{width: '100%'}}
        layout={'vertical'}
        fields={fields}
        onFieldsChange={(f) => {
          const [v] = f;
          const name = (v.name as any[])[0];
          (policyViewState as any).policy[name] = v.value;
        }}
      >
        <Card style={{width: '100%'}} bordered={false}>
          {
            policy.map((e, key) => {
              return (<Row className={styles.row}>
                <Typography className={styles.labelRow}>{e.label}</Typography>
                <Form.Item
                  name={e.name}
                  className={e.styles}
                >
                  <Input
                    maxLength={6}
                    disabled={true}
                  />
                </Form.Item>
              </Row>)
            })
          }
          <Row className={styles.row}>
            <Typography className={styles.labelRow}>Страховые риски</Typography>
            <List
              size="small"
              bordered
              style={{width: '35%'}}
              dataSource={
                policyViewState.policy.insurance_risks
                  .filter(e => e.is_fixed || e.amount)
                  .map<string>( e => policyViewState.insuranceRiskTypeName[e.type])
              }
              renderItem={item => <List.Item>{item}</List.Item>}
            />
          </Row>
          <Row className={styles.row}>
            <Typography className={styles.labelRow}>Страховая сумма по несчастным случаям</Typography>
            <Input
              maxLength={10}
              className={styles.inputShort}
              disabled={true}
              value={policyViewState.policy.insurance_risks.find( e => e.type === 'health' )!.amount || ''}
            />
          </Row>
          <Row className={styles.row}>
            <Typography className={styles.labelRow}>Страховая сумма по гражданской ответственности</Typography>
            <Input
              maxLength={10}
              className={styles.inputShort}
              disabled={true}
              value={policyViewState.policy.insurance_risks.find( e => e.type === 'civil' )!.amount || ''}
            />
          </Row>
          <br/>
          <Row className={styles.row}>
            <Typography className={styles.labelRow}>Pdf-файл</Typography>
            <Form.Item
              className={styles.inputWrite}
              name={'pdf'}
            >
              <a href={policyViewState.policy?.file?.url} target={'_blank'} rel="noreferrer">
                <FilePdfOutlined/>
              </a>
            </Form.Item>
          </Row>
        </Card>
        <br/>
        <Card style={{width: '100%'}} bordered={true}>
          <Title level={3}>Информация о застрахованном лице</Title>
          {insuredPerson.map((e) => {
            return (
              <Row>
                <Typography className={styles.labelRow}>{e.label}</Typography>
                <Form.Item
                  className={e.styles}
                  name={e.name}
                >
                  <Input disabled={true}/>
                </Form.Item>
              </Row>
            )
          })}
        </Card>
        <br/>
        <Card>
          <Title level={3}>Информация о застрахованном объекте</Title>
          {insuredVehicle.map((e) => {
            return (<Row>
              <Typography className={styles.labelRow}>{e.label}</Typography>
              <Form.Item
                className={e.styles}
                name={e.name}
              >
                <Input disabled={true}/>
              </Form.Item>
            </Row>)
          })}
        </Card>
      </Form>
    </>
  );
});
