import {makeAutoObservable, runInAction} from "mobx";
import {ClientProvider} from "../../utils/apiClient/clientProvider";
import {ListClient} from "../../types/list-client";
import {DictionaryTerm} from "../../types/dictionary-term";
import {dictionaryListState} from "../dictionary/dictionary-list.state";

class ClientsListState {
  clients: ListClient[] = [];
  vehicleTypes: DictionaryTerm[] = []
  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
    this.getVehicleTypes();
  }

  setLoading = (v: boolean) => this.loading = v;

  setClients(clients: ListClient[]) {
    this.clients = clients;
  }

  private async getVehicleTypes() {
    const res = await dictionaryListState.getTermsListRequest('vehicle_types');
    if ( res.status === 200 ) {
      runInAction(() => {
        this.vehicleTypes = res.data.data;
      });
    }
  }

  async getList(search?:string, typeId?: string, showDeleted: boolean = false){
    try {
      this.setLoading(true);
      const res = await ClientProvider.authorized.get('/client-user', {
        params:{
          vehicle_type_id: typeId,
          search: search,
          page: 1,
          perpage: 100,
          show_deleted: showDeleted ? 1 : 0,
        }
      });
      if ( !res.data.errors ) {
        runInAction(() => {
          this.clients = res.data;
        })
      } else {
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }

  async setDelete(id: string) {
    try {
      this.setLoading(true);
      const res = await ClientProvider.authorized.delete(`/user/${id}`);
      if ( res.data.errors ) {
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }
}

export const clientsListState: ClientsListState = new ClientsListState();
