import {observer} from "mobx-react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {ApplicationForm} from "../../components/application-form";
import {Button, Card, Checkbox, Input, message, Modal, Popconfirm, Row} from "antd";
import {applicationEditState} from "../../../../state/application/application-edit.state";
import Title from "antd/lib/typography/Title";

export const ApplicationEditPage = observer(() => {
  const history = useNavigate();
  const { id } = useParams() as any;
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [reasonCheck, setReasonCheck] = useState(false);
  const [reasonText, setReasonText] = useState('');
  const queryParams = new URLSearchParams(window.location.search);
  const view = !!queryParams.get('view');

  const onReasonModalCancel = () => {
    setReasonText('');
    setReasonCheck(false);
    setShowReasonModal(false);
  }

  const onReasonModalOk = () => {
    if ( reasonText === '' ) {
      return message.error("Укажите причину отклонения заявки");
    }

    setShowReasonModal(false);
    applicationEditState.disapprove(id, reasonText, reasonCheck);
  }

  useEffect(() => {
    applicationEditState.resetApplication();
    applicationEditState.get(id)
        .catch( e => {
          console.log(e);
          history('/my-applications', {replace: true});
        } )
  }, [history, id]);

  const toUnderwriterOrConfirm = () => {
    if ( applicationEditState.application?.status === 'inprogress' ) {

      applicationEditState.triggerToUnderwriter();
    } else if ( applicationEditState.application?.status === 'overview' ) {
      applicationEditState.triggerConfirmed();
    }
  }

  const isView = view || applicationEditState.application?.status === 'disapproved';
  return (
    <>
        <Card style={{ width: "100%" }} bordered={true}>
          <Row justify={"space-between"}>
            <Title level={5}>{isView ? "Просмотр": "Редактирование"}</Title>
            {
              !isView && (
                <Row>
                  <Button danger={true} type={'primary'} onClick={() => setShowReasonModal(true)} loading={applicationEditState.loading}>
                    Отклонить
                  </Button>
                  <Popconfirm onConfirm={toUnderwriterOrConfirm} okText={"Ок"} cancelText={"Отмена"} title={"Подтверждение операции"}>
                    <Button type='primary' style={{marginLeft: 10}} loading={applicationEditState.loading}>
                      {applicationEditState.application?.status === 'overview' ? 'Одобрить': 'Перевести на андеррайтера'}
                    </Button>
                  </Popconfirm>
                </Row>
              )
            }
          </Row>
          <br/>
          <ApplicationForm
            onSave={() => window.location.href = '/my-applications'}
            disapproved={applicationEditState.application?.status === 'disapproved'}
            view={isView}
          />
        </Card>
      <Modal
          visible={showReasonModal}
          title="Вы точно хотите отклонить заявку? Укажите причину отклонения заявки"
          onOk={onReasonModalOk}
          onCancel={onReasonModalCancel}
          width={'80%'}
          footer={[
            <Checkbox checked={reasonCheck} onChange={e => setReasonCheck(e.target.checked)}>
              Можно вернуть на доработку
            </Checkbox>,
            <Button key="back" onClick={onReasonModalCancel}>
              Отмена
            </Button>,
            <Button key="submit" type="primary" loading={applicationEditState.loading} danger={true} onClick={onReasonModalOk}>
                Отклонить
            </Button>,
          ]}
      >
        <Input onChange={e => setReasonText(e.target.value)} value={reasonText} placeholder={"Причина"}/>
      </Modal>
    </>
  )
});

