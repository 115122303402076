import {Button, Col, Input, Row, Space, Table} from "antd";
import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {Link, useLocation} from "react-router-dom";
import {DeleteTwoTone, EditTwoTone, EyeTwoTone} from "@ant-design/icons";
import {User} from "../../../../types/user";
import Popconfirm from "antd/es/popconfirm";
import Title from "antd/lib/typography/Title";
import MaskedInput from "antd-mask-input";
import {dictionaryListState} from "../../../../state/dictionary/dictionary-list.state";

const {Column} = Table;
const {Search} = Input;

export const DictionaryListPage = observer(() => {
  const [visible, setVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const p = useLocation().pathname;
  let listModel: { title: string, dataIndex: string | string[], sorter?: (a: any, b: any)=> any}[] = [];
  let pageName: string = ''

  if (p === '/vehicle-model') {
    pageName = 'Модель ТС'
    listModel = [
      {title: "Тип ТС", dataIndex: ['parent', 'parent', 'name']},
      {title: "Марка", dataIndex: ['parent', 'name']},
      {title: "Модель", dataIndex: 'name'},
      {title: "Полное название модели", dataIndex: ['data', 'full_name']},
      {title: "Тариф", dataIndex: ['data', 'tariff']},
    ]
  } else if (p === '/vehicle-brand') {
    pageName = 'Марки ТС'
    listModel = [
      {title: "Тип ТС", dataIndex: ['parent', 'name']},
      {title: "Марки ТС", dataIndex: 'name'},
    ]
  } else if (p === '/vehicle-type') {
    pageName = 'Тип ТС'
    listModel = [
      {title: "Тип ТС", dataIndex: 'name'},
    ]
  } else if (p === '/rate-daily') {
    pageName = 'Зависимость весовых коэффициентов от суток'
    listModel = [
      {title: "Сутки", dataIndex: 'name', sorter: (a, b) => a.name - b.name },
      {title: "Коэффициент", dataIndex: ['data', 'coefficient']},
    ]
  } else if (p === '/rate-hourly') {
    pageName = 'Зависимость весовых коэффициентов от часов'
    listModel = [
      {title: "Часы", dataIndex: 'name', sorter: (a, b) => a.name - b.name },
      {title: "Коэффициент", dataIndex: ['data', 'coefficient']},
    ]
  }

  useEffect(() => {
    if (dictionaryListState.dictionaries.length) {
      dictionaryListState.getTermsList(dictionaryListState.getDictFromCurrentRoute(p)!.name);
    }
  }, [dictionaryListState.dictionaries.length, p]);

  function cancel(e: any) {
    console.log(e);
  }

  const onSearch = (value: string) => {
    dictionaryListState.getTermsList(dictionaryListState.getDictFromCurrentRoute(p)!.name, value)
  }

  const renderActions = (v: any, record: User) => (
    <Row justify={"space-around"}>
      <Link to={`${p}/${record.id}?view=true`}>
        <EyeTwoTone
          style={{fontSize: '2em'}}
        />
      </Link>
      <Link to={`${p}/${record.id}`}>
        <EditTwoTone
          style={{fontSize: '2em'}}
        />
      </Link>
      <Popconfirm
        title={`Вы уверены, что хотите удалить запись?`}
        onConfirm={async () => {
          await dictionaryListState.deleteTerm(p, record.id);
          await dictionaryListState.getTermsList(dictionaryListState.getDictFromCurrentRoute(p)!.name)
        }}
        onCancel={cancel}
        okText="Да"
        cancelText="Нет"
      >
        <DeleteTwoTone
          style={{fontSize: '2em'}}
        />
      </Popconfirm>
    </Row>
  );

  const handleTableChange = (pagination: any) => {
    dictionaryListState.getTermsList(dictionaryListState.getDictFromCurrentRoute(p)!.name, undefined, pagination.current);
  };

  return (
    <>
      <Col>
        <Row justify={"space-between"}>
          <Space direction="vertical">
            <Title level={4} style={{textAlign: 'left'}}>
              {pageName}
            </Title>
          </Space>
          <Link to={`${p}/add`}>
            <Button type="primary">
              Добавить
            </Button>
          </Link>
        </Row>
        <Row justify={"space-between"}>
          <Search
            placeholder="Введите название"
            allowClear
            enterButton="Поиск"
            style={{width: 250}}
            onSearch={onSearch}
          />
          {visible && (
            <Row>
              <MaskedInput placeholder={'Дата создания от'} style={{width: 150, marginLeft: 10, textAlign: "center"}}
                           mask="11-11-1111"/>
              <MaskedInput placeholder={'Дата создания до'} style={{width: 150, marginLeft: 10, textAlign: "center"}}
                           mask="11-11-1111"/>
            </Row>
          )}
        </Row>
        <br/>
        <br/>

        {/*<TermTable/>*/}
        <Table
            pagination={{
              current: dictionaryListState.currentPage,
              pageSize: 100,
              total: dictionaryListState.termsCount,
              showSizeChanger: false
            }}
            onChange={handleTableChange}
            dataSource={dictionaryListState.terms} >
          {listModel.map((e: any) => {
            return (
              <Column
                title={e.title} dataIndex={e.dataIndex} key={e.dataIndex} sorter={e.sorter} showSorterTooltip={false} sortDirections={['ascend', 'descend', 'ascend']} defaultSortOrder={'ascend'}
              />
            )
          })}
          <Column title="Действия" render={renderActions} width={150}/>
        </Table>
      </Col>
    </>
  )
});
