import {makeAutoObservable, runInAction} from "mobx";
import { User } from "../../types/user";
import {ClientProvider} from "../../utils/apiClient/clientProvider";
import {message} from "antd";

export class UserEditState  {
    private _id?: string;
    private _name: string = '';
    private _lastname: string = '';
    private _firstname: string = '';
    private _patronymic: string = '';
    private _phone_number: string = '';
    private _role: string[] = [];
    private _password: string = '';
    private _email: string = '';
    loading: boolean = false;
    saveResponse?: User;

    setLoading = (v: boolean) => this.loading = v;

    get id(): string | undefined {
        return this._id;
    }

    set id(value: string | undefined) {
        this._id = value;
    }

    get phone_number(): string {
        return this._phone_number;
    }

    set phone_number(value: string) {
        this._phone_number = value;
    }

    get role(): string[] {
        return this._role;
    }

    set role(value: string[]) {
        this._role = value;
    }

    get password(): string {
        return this._password;
    }

    set password(value: string) {
        this._password = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get lastname(): string {
        return this._lastname;
    }

    set lastname(value: string) {
        this._lastname = value;
    }

    get firstname(): string {
        return this._firstname;
    }

    set firstname(value: string) {
        this._firstname = value;
    }

    get patronymic(): string {
        return this._patronymic;
    }

    set patronymic(value: string) {
        this._patronymic = value;
    }

    constructor() {
        makeAutoObservable(this);
    }

    reset() {
        this.id = undefined;
        this.name = '';
        this.lastname = '';
        this.firstname = '';
        this.patronymic = '';
        this.phone_number = '';
        this.role = [];
        this.email = '';
        this.password = '';
    }

    prefill(data: Partial<User>) {
        this.id = data.id;
        this.name = data.name!;
        this.firstname = data.firstname!;
        this.lastname = data.lastname!;
        this.patronymic = data.patronymic!;
        this.phone_number = data.phone_number!;
        this.role = data.role!;
        this.email = data.email!;
        this.password = data.password!;
    }

    async trySave() {
        try {
            let res: any;
            this.setLoading(true);
            const phone = this._phone_number.replace(/[()-]/g,"")
            const [lastname, firstname, patronymic] = this._name.split(' ')
            const data: any = {
                firstname: firstname,
                lastname: lastname,
                patronymic: patronymic,
                phone_number: phone,
                email: this._email,
                role: this._role
            };
            if ( this.id ) {
                res = await ClientProvider.authorized.put(`/service-user/${this.id}`, data);
            } else {
                data.password = this.password;
                res = await ClientProvider.authorized.post('/service-user', data);
            }
            if ( !res.data.errors ) {
                runInAction(() => {
                    this.saveResponse = res.data;
                });
            } else {
                console.error(res);
            }
        } catch (e) {
            message.error("Ошибка сохранения. Возможно указанный e-mail или телефон уже используются другим пользователем");
        } finally {
            this.setLoading(false);
        }
    }
}


export const userEditState: UserEditState = new UserEditState();
