import {makeAutoObservable, runInAction} from "mobx";
import {
    AuthenticationDetails, CognitoUser,
    CognitoUserPool
} from 'amazon-cognito-identity-js';
import {message} from "antd";

class ForgetState {
    private _email: string = '';
    private _loading: boolean = false;
    private _success: boolean = false;

    get success(): boolean {
        return this._success;
    }

    set success(value: boolean) {
        this._success = value;
    }

    get loading(): boolean {
        return this._loading;
    }

    set loading(value: boolean) {
        this._loading = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    constructor() {
        makeAutoObservable(this);
    }

    async requestReset() {
        runInAction(() => {
            try {
                this._loading = true;

                const userPool = new CognitoUserPool({
                    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
                    ClientId: process.env.REACT_APP_COGNITO_APP_ID!
                });

                const cognitoUser = new CognitoUser({
                    Username: this._email,
                    Pool: userPool
                });

                cognitoUser.forgotPassword({
                    onSuccess: (res => {
                        this._loading = false;
                        this._success = true;
                        message.success('Ссылка для восстановления отправлена');
                    }),
                    onFailure: (e => {
                        console.log(e);
                        this._loading = false;
                        message.error('Произошла ошибка, возможно указана неверная почта');
                    }),
                })
            } catch (e) {
                console.log(e);
                this._loading = false;
            }
        });
    }
}

export const forgetState: ForgetState = new ForgetState();
