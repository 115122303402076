import {makeAutoObservable, runInAction} from "mobx";
import {ClientProvider} from "../../utils/apiClient/clientProvider";
import {ListPolicy} from "../../types/list-policy";
import moment from "moment";

class PolicyListState {
  policies: ListPolicy[] = [];
  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading = (v: boolean) => this.loading = v;

  setPolicies(policies: ListPolicy[]) {
    this.policies = policies;
  }

  async getList(demo?: boolean, search?: string, birthdate?: string, showDeleted: boolean = false) {
    try {
      this.setLoading(true);
      const res = await ClientProvider.authorized.get(`/policy`, {
        params: {
          demo,
          search,
          birthdate,
          show_deleted: showDeleted ? 1: 0
        }
      });
      if (!res.data.errors) {
        runInAction(() => {
          this.policies = res.data.map((e: any) => {
            return {
              ...e,
              price: (+e.price + +e.civil_bonus_separately + +e.health_bonus_separately).toFixed(2),
              type_readable: e.type === 'hourly' ? 'Почасовой': 'Посуточный',
              created_at: moment(e.created_at).format('DD.MM.YYYY HH:mm'),
            }
          });
        })
      } else {
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }
}

export const policyListState: PolicyListState = new PolicyListState();
