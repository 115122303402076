import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import { BaseClient } from "./baseClient";
import {storage} from "../storage";
import {CognitoRefreshToken, CognitoUser, CognitoUserPool} from "amazon-cognito-identity-js";
import {loginState} from "../../state/login/login.state";
import {systemState} from "../../state/system.state";

export class AuthorizedClient extends BaseClient {
  static onSuccessResponse(res: AxiosResponse): AxiosResponse {
    if (res.status >= 200 && res.status <= 400) {
      return res;
    }

    throw new Error(res.data);
  }

  static onFailedResponse(err: any): any {
    if ( !storage.authToken ) {
      return Promise.reject(err);
    }

    if (err.response && err.response.status === 401) {
      const userPool = new CognitoUserPool({
        UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
        ClientId: process.env.REACT_APP_COGNITO_APP_ID!
      });
      const token = new CognitoRefreshToken({RefreshToken: storage.refreshToken as string});
      const cognitoUser = new CognitoUser({
        Username: storage.email as string,
        Pool: userPool
      });

      cognitoUser.refreshSession(token, (e, session) => {
        if (!session) {
          if ( window.location.pathname !== '/login' ) {
            window.location.href = '/login';
          }
          return
        }
        const accessToken = session.getAccessToken().getJwtToken();
        const refreshToken = session.getRefreshToken().getToken();
        loginState.loginResponse = {accessToken, refreshToken};
        systemState.setAuthToken(accessToken);
        systemState.setRefreshToken(refreshToken);
        err.config.headers['Authorization'] = `Bearer ${accessToken}`;
        return axios.request(err.config);
      });
    }
    return Promise.reject(err);
  }

  static onBeforeRequest(config: AxiosRequestConfig){
     //config.headers!['Authorization'] = `Bearer 8bbc5b90-ab99-4d3d-b21f-dfd646af8d41`;
     config.headers!['Authorization'] = `Bearer ${storage.authToken}`;
    return config;
  }
}
