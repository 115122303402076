import {observer} from "mobx-react";
import {Button, Card, Col, Form, Image, Input, message, Row, Select, Spin, Table, Typography} from "antd";
import React, {useEffect} from "react";
import {autorun} from "mobx";
import {FieldData} from "rc-field-form/lib/interface";
import {Link, useNavigate} from "react-router-dom";
import {EyeTwoTone} from "@ant-design/icons";
import {clientEditState} from "../../../../state/clients/client-edit.state";
import styles from './index.module.css';
import Title from "antd/lib/typography/Title";
import Column from "antd/lib/table/Column";
import {ClientVehicleTable} from "../client-vehicle-table";
import {applicationEditState} from "../../../../state/application/application-edit.state";
import {Client} from "../../../../types/client";
import {get} from "lodash";

const {Option} = Select;

interface Props {
  onSave: () => void;
  view?: boolean
}

export const ClientForm = observer(({onSave, view}: Props) => {
  const history = useNavigate();
  useEffect(() => {
    return autorun(() => {
      if (clientEditState.saveResponse) {
        onSave();
        message.success("Сохранено");
        clientEditState.saveResponse = undefined;
      }
    });
  });
  const onApplication = (v: any, record: any) => (
      <Link to={`/applications/${record.user_id}?view=true`}>{record.number}</Link>
  );

  const renderActions = (v: any, record: Client) => (
      <Row justify={"space-around"}>
        <Link to={`/policy/${record.id}`}>
          <EyeTwoTone
              style={{fontSize: '2em'}}
          />
        </Link>
      </Row>
  );

  if ( !clientEditState.client ){
    return <Spin/>
  }

  const fields: FieldData[] = [
      'lastname', 'firstname', 'patronymic', 'phone_number', 'passport.date_of_birth', 'gender', 'passport.place_of_birth',
      'passport.series', 'passport.number', 'passport.number', 'passport.issuer', 'passport.issued_at', 'passport.department_code',
      'passport.registration', 'email', 'policies'
  ].map( e => ({
    name: e,
    value: get(clientEditState.client, e)
  }));
  return (
    <>
      <Form
        style={{width: '100%'}}
        layout={'vertical'}
        onFinish={() => clientEditState.trySave()}
        fields={fields}
        onFieldsChange={(f) => {
          const [v] = f;
          const name = (v.name as any[])[0];
          (clientEditState as any).client[name] = v.value;
        }}
      >
        <Row className={styles.row}>
          <Typography className={styles.labelRow}>Номер телефона</Typography>
          <Form.Item
              name={'phone_number'}
              rules={[{message: 'Укажите телефон'}]}
          >
            <Input disabled={true}/>
          </Form.Item>
        </Row>
        <Row>
          <Typography className={styles.labelRow}>Имя*</Typography>
          <Form.Item
              className={styles.inputWrite}
              name={'firstname'}
              required={true}
              rules={[
                {type: "string", required: true, message: 'Укажите Имя'},
                {pattern: /^[а-яА-ЯёЁa-zA-Z\'\-\ ]+$/, message: 'Имя должно содержать только русские или английские буквы, а также символы “-” (дефис), “\'“ (верхний апостроф)' }
              ]}
          >
            <Input disabled={view} />
          </Form.Item>
        </Row>
        <Row>
          <Typography className={styles.labelRow}>Фамилия*</Typography>
          <Form.Item
              className={styles.inputWrite}
              name={'lastname'}
              required={true}
              rules={[
                {type: "string", required: true, message: 'Укажите Фамилию'},
                {pattern: /^[а-яА-ЯёЁa-zA-Z\'\-\ ]+$/, message: 'Фамилия должна содержать только русские или английские буквы, а также символы “-” (дефис), “\'“ (верхний апостроф)' }]}
          >
            <Input disabled={view} />
          </Form.Item>
        </Row>
        <Row>
          <Typography className={styles.labelRow}>Отчество</Typography>
          <Form.Item
              className={styles.inputWrite}
              name={'patronymic'}
              rules={[{pattern: /^[а-яА-ЯёЁa-zA-Z\'\-\ ]+$/, message: 'Отчество должно содержать только русские или английские буквы, а также символы “-” (дефис), “\'“ (верхний апостроф)' }]}
          >
            <Input disabled={view}/>
          </Form.Item>
        </Row>
        <Row>
          <Typography className={styles.labelRow}>Дата рождения*</Typography>
          <Form.Item
              name={'passport.date_of_birth'}
              required={true}
              rules={[{type: "string", required: true, message: 'Укажите дату рождения'}]}
          >
            <Input disabled={view}/>
          </Form.Item>
        </Row>
        <Row>
          <Typography className={styles.labelRow}>Пол*</Typography>
          <Form.Item
              className={styles.inputShort}
              name={'gender'}
              rules={[{type: "string", required: true, message: 'Укажите пол'}]}
          >
            <Select
                disabled={view}
                allowClear
                placeholder="Выберите пол"
            >
              <Option value={"M"} key={"m"}>Мужской</Option>
              <Option value={"W"} key={"w"}>Женский</Option>
            </Select>
          </Form.Item>
        </Row>
        <Row>
          <Typography className={styles.labelRow}>Место рождения*</Typography>
          <Form.Item
              className={styles.inputWrite}
              name={'passport.place_of_birth'}
              required={true}
              rules={[{type: "string", required: true, message: 'Укажите место рождения'}]}
          >
            <Input disabled={view} />
          </Form.Item>
        </Row>
        <Row>
          <Typography className={styles.labelRow}>Серия и номер паспорта*</Typography>
          <Form.Item
              className={styles.inputPas}
              name={'passport.series'}
              required={true}
              rules={[{type: "string", required: true, message: 'Укажите серию паспорта'}]}
          >
            <Input disabled={view}/>
          </Form.Item>
          <Form.Item
              className={styles.inputPas}
              name={'passport.number'}
              required={true}
              rules={[{type: "string", required: true, message: 'Укажите номер паспорта'}]}
          >
            <Input disabled={view}/>
          </Form.Item>
        </Row>
        <Row>
          <Typography className={styles.labelRow}>Паспорт выдан*</Typography>
          <Form.Item
              className={styles.inputWrite}
              name={'passport.issuer'}
              required={true}
              rules={[{type: "string", required: true, message: 'Укажите кем выдан паспорт'}]}
          >
            <Input disabled={view} />
          </Form.Item>
        </Row>
        <Row>
          <Typography className={styles.labelRow}>Дата выдачи*</Typography>
          <Form.Item
              className={styles.inputShort}
              name={'passport.issued_at'}
              required={true}
              rules={[{type: "string", required: true, message: 'Укажите дату выдачи паспорта'}]}
          >
            <Input disabled={view}/>
          </Form.Item>
        </Row>
        <Row>
          <Typography className={styles.labelRow}>Код подразделения*</Typography>
          <Form.Item
              className={styles.inputShort}
              name={'passport.department_code'}
              required={true}
              rules={[{type: "string", required: true, message: 'Укажите код подразделения'}]}
          >
            <Input disabled={view}/>
          </Form.Item>
        </Row>
        <Row>
          <Typography className={styles.labelRow}>Место регистрации*</Typography>
          <Form.Item
              className={styles.inputWrite}
              name={'passport.registration'}
              required={true}
              rules={[{type: "string", required: true, message: 'Укажите место регистрации'}]}
          >
            <Input disabled={view} />
          </Form.Item>
        </Row>
        <Row>
          <Typography className={styles.labelRow}>Email</Typography>
          <Form.Item
              className={styles.inputWrite}
              name={'email'}
              required={true}
          >
            <Input disabled={view}/>
          </Form.Item>
        </Row>
        <Row className={styles.row}>
          <Typography className={styles.labelRow}>Разворот паспорта с фотографией</Typography>
          <Image style={{height: '45px', width: '45px'}} src={clientEditState.client?.passport.photo_front.url}/>
        </Row>
        <Row className={styles.row}>
          <Typography className={styles.labelRow}>Разворот паспорта с регистрацией</Typography>
          <Image style={{height: '45px', width: '45px'}} src={clientEditState.client?.passport.photo_reg.url}/>
        </Row>
        <br/>
        <Card>
          <Title level={3}>Данные о ТС</Title>
          {
            clientEditState.client && !clientEditState.loading && (
              <ClientVehicleTable />
            )
          }
        </Card>
        <br/>
        <Card>
          <Title level={3}>Заявка на регистрацию</Title>
          <Col>
            <Table rowKey={"id"} dataSource={[clientEditState.client ? clientEditState.client!.registration_request : [] ]}>
              <Column title="Id заявки" render={onApplication} />
              <Column title="Дата одобрения" dataIndex="approved_at" key="approved_at"/>
            </Table>
          </Col>
        </Card>
        <br/>
        <Card>
          <Title level={3}>Полисы</Title>
          <Col>
            <Table rowKey={"id"} dataSource={clientEditState.client?.policies}>
              <Column title="Номер полиса" dataIndex="number" key="number" width={30}/>
              <Column title="Тип полиса" dataIndex="type" key="type"/>
              <Column title="Дата начала действия полиса" dataIndex="begins_at" key="begins_at"/>
              <Column title="Дата окончания действия полиса" dataIndex="ends_at" key="ends_at"/>
              <Column title="Стоимость" dataIndex="price" key="price"/>
              <Column title="Действия" render={renderActions}/>
            </Table>
          </Col>
        </Card>
        <br/>
        {
          !view && (
            <Row justify={"end"}>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={clientEditState.loading}>
                  Сохранить
                </Button>
              </Form.Item>
              <Form.Item>
                <Button type='ghost' style={{marginLeft: 10}} onClick={()=>{history(-1)}} loading={applicationEditState.loading}>
                  Отменить
                </Button>
              </Form.Item>
            </Row>
          )
        }
      </Form>
    </>
  );
},);
