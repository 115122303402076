import {makeAutoObservable, runInAction, toJS} from "mobx";
import { Client } from "../../types/client";
import {ClientProvider} from "../../utils/apiClient/clientProvider";
import moment from "moment";
import {set} from "lodash";
import {message} from "antd";

export class ClientEditState {
    loading: boolean = false;
    saveResponse?: Client;

    public client?: Client;

    setLoading = (v: boolean) => this.loading = v;

    constructor() {
        makeAutoObservable(this);
    }

    async trySave() {
        try {
            let res: any;
            this.setLoading(true);
            const client = toJS(this).client!;
            const data: any = {};
            for (const key in client) {
                set(data, key, (client as any)[key]);
            }
            res = await ClientProvider.authorized.put(`/client-user/${this.client!.id}`, {
                passport: {
                    date_of_birth: data.passport.date_of_birth,
                    department_code: data.passport.department_code,
                    issued_at: data.passport.issued_at,
                    issuer: data.passport.issuer,
                    number: data.passport.number,
                    place_of_birth: data.passport.place_of_birth,
                    registration: data.passport.registration,
                    series: data.passport.series
                },
                gender: data.gender,
                firstname: data.firstname,
                lastname: data.lastname,
                patronymic: data.patronymic,
                vehicle: {
                  acquisition_year: data.vehicle.acquisition_year,
                  description: data.vehicle.description
                }
            });
            if ( !res.data.errors ) {
                message.success("Клиент обновлён");
                runInAction(() => {
                    this.saveResponse = res.status;
                });
            } else {
                message.error("Ошибка");
                console.error(res);
            }
        } finally {
            this.setLoading(false);
        }
    }

    async get( id?: string){
        try {
            this.setLoading(true);
            const res = await ClientProvider.authorized.get(`/client-user/${id}`);
            if ( !res.data.errors ) {
                runInAction(() => {
                    this.client = {} as Client;
                    for ( const key in res.data ) {
                        (this.client as any)[key] = res.data[key];
                    }
                    this.client.passport.date_of_birth = moment(res.data.passport.date_of_birth).format('DD.MM.YYYY');
                    this.client.passport.issued_at = moment(res.data.passport.issued_at).format('DD.MM.YYYY');
                    this.client.policies = this.client.policies.map( e => ({
                        ...e,
                        price: (+e.price + +(e.civil_bonus_separately || 0) + +(e.health_bonus_separately || 0)).toFixed(2),
                        begins_at: moment(e.begins_at).format('DD.MM.YYYY'),
                        ends_at: moment(e.ends_at).format('DD.MM.YYYY'),
                    }));
                    if ( this.client.registration_request.approved_at ) {
                        this.client.registration_request.approved_at =
                          moment(this.client.registration_request.approved_at).format('DD.MM.YYYY HH:mm')
                    }
                    if ( this.client.vehicle?.photos?.length === 2 ) {
                        const ph = this.client.vehicle.photos;
                        if ( ph[0].created_at && ph[1].created_at ) {
                            this.client.vehicle.photos = this.client.vehicle.photos.sort(
                                (a, b) => moment(a.created_at).isBefore(moment(b.created_at)) ? -1 : 1
                            );
                        }
                    }
                })
            } else {
                console.error(res);
            }
        } finally {
            this.setLoading(false);
        }
    }
}

export const clientEditState: ClientEditState = new ClientEditState();
