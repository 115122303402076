import {observer} from "mobx-react";
import {Button, Card, Checkbox, Col, DatePicker, Form, Image, Input, message, Row, Select, Spin, Table, Typography} from "antd";
import React, {useEffect, useRef} from "react";
import {autorun, runInAction} from "mobx";
import styles from './index.module.css';
import {FieldData} from "rc-field-form/lib/interface";
import {useNavigate} from "react-router-dom";
import MaskedInput from "antd-mask-input";
import Title from "antd/lib/typography/Title";
import CameraOutlined from "@ant-design/icons/lib/icons/CameraOutlined";
import {applicationEditState} from "../../../../state/application/application-edit.state";
import {get} from "lodash";
import {Label} from "react-query/types/devtools/Explorer";
import Column from "antd/lib/table/Column";
import {clientEditState} from "../../../../state/clients/client-edit.state";
import {FormInstance} from "rc-field-form/es/interface";
import locale from "antd/es/date-picker/locale/ru_RU";

const moment = require('moment');
const {Option} = Select;


interface Props {
    onSave: () => void;
    disapproved: boolean;
    view: boolean;
}

export const ApplicationForm = observer(({onSave, disapproved, view}: Props) => {
    const history = useNavigate();
    const form = useRef(null);
    const status = applicationEditState.application?.status;
    const under = status?.includes('overview');
    useEffect(() => {
        return autorun(() => {
            if (applicationEditState.saveResponse) {
                onSave();
                applicationEditState.saveResponse = undefined;
            }
        });
    });
    useEffect(() => {
        if (applicationEditState.disapprovedBid || applicationEditState.movedBid) {
            onSave();
        }
    }, [applicationEditState.disapprovedBid, applicationEditState.movedBid])

    useEffect(() => {
        if (applicationEditState.triggeredToUnderwriter || applicationEditState.triggeredConfirmed) {
            const f = (form.current as unknown as FormInstance);
            const er = f.validateFields().then((e) => {
                f.submit();
                if (applicationEditState.triggeredToUnderwriter) {
                    applicationEditState.toUnderwriter(applicationEditState.application!.id);
                } else {
                    applicationEditState.confirm(applicationEditState.application!.id);
                }
            }).catch(() => {
                message.error("Исправьте все ошибки в форме")
            });
        }
    }, [applicationEditState.triggeredToUnderwriter, applicationEditState.triggeredConfirmed]);

    if (!applicationEditState.application) {
        return (
            <Spin/>
        );
    }

    const fields: FieldData[] = ['status', 'status_readable', 'phone_number', 'vehicle.price', 'vehicle.model.name',
        'vehicle.brand.name', 'vehicle.type.name', 'firstname', 'lastname', 'patronymic', 'passport.series', 'passport.number', 'passport.date_of_birth', 'gender',
        'passport.place_of_birth', 'passport.issuer', 'passport.issued_at', 'passport.department_code', 'vehicle.type.name', 'passport.registration',
        'vehicle.description', 'vehicle.acquisition_year', 'registration_request.disapprove_reason', 'registration_request.can_be_returned', 'registration_request.disapproved_at'].map(e => ({
        name: e,
        value: get(applicationEditState.application, e)
    }));
    return (
        <>
            <Form
                ref={form}
                style={{width: '100%'}}
                layout={'vertical'}
                onFinishFailed={() => {
                    message.error("Исправьте все ошибки в форме")
                }}
                onFinish={() => applicationEditState.trySave()}
                fields={fields}
                // validateTrigger={JSON.stringify(applicationEditState.triggeredToUnderwriter)}
                onFieldsChange={(f) => {
                    const [v] = f;
                    const name = (v.name as any[])[0];
                    (applicationEditState as any).application[name] = v.value;
                }}
            >
                <Card style={{width: '100%'}} bordered={true}>
                    <Title level={3}>Информация о заявке</Title>
                    <Row className={styles.row}>
                        <Typography className={styles.labelRow}>Статус заявки</Typography>
                        <Form.Item
                            name={'status_readable'}
                        >
                            <Input disabled={true}/>
                        </Form.Item>
                    </Row>
                    <Row className={styles.row}>
                        <Typography className={styles.labelRow}>Номер телефона</Typography>
                        <Form.Item
                            name={'phone_number'}
                            rules={[{message: 'Укажите телефон'}]}
                        >
                            <MaskedInput mask="+7(111)-111-11-11" disabled={true}/>
                        </Form.Item>
                    </Row>
                    <Row className={styles.row}>
                        <Typography className={styles.labelRow}>Разворот паспорта с фотографией</Typography>
                        <Image style={{height: '45px', width: '45px'}}
                               src={applicationEditState.application?.passport.photo_front.url}/>
                    </Row>
                    <Row className={styles.row}>
                        <Typography className={styles.labelRow}>Разворот паспорта с регистрацией</Typography>
                        <Image style={{height: '45px', width: '45px'}}
                               src={applicationEditState.application?.passport.photo_reg.url}/>
                    </Row>
                    <Row className={styles.row}>
                        <Typography className={styles.labelRow} style={{paddingTop: 17}}>Фото транспорта 1</Typography>
                        <Image style={{height: '45px', width: '45px'}}
                               src={applicationEditState.application?.vehicle.photos[0]?.url}/>
                    </Row>
                    <Row className={styles.row}>
                        <Typography className={styles.labelRow} style={{paddingTop: 17}}>Фото транспорта 2</Typography>
                        <Image style={{height: '45px', width: '45px'}}
                               src={applicationEditState.application?.vehicle.photos[1]?.url}/>
                    </Row>
                    <br/>
                    <Row className={styles.row}>
                        <Typography className={styles.labelRow}>Тип ТС</Typography>
                        <Form.Item
                            className={styles.inputWrite}
                            name={'vehicle.type.name'}
                        >
                            <Input disabled={true}/>
                        </Form.Item>
                    </Row>
                    <Row className={styles.row}>
                        <Typography className={styles.labelRow}>Марка ТС</Typography>
                        <Form.Item
                            className={styles.inputWrite}
                            name={'vehicle.brand.name'}
                        >
                            <Input disabled={true}/>
                        </Form.Item>
                    </Row>
                    <Row className={styles.row}>
                        <Typography className={styles.labelRow}>Модель ТС</Typography>
                        <Form.Item
                            className={styles.inputWrite}
                            name={'vehicle.model.name'}
                        >
                            <Input disabled={true}/>
                        </Form.Item>
                    </Row>
                    <Row className={styles.row}>
                        <Typography className={styles.labelRow}>Цена ТС, руб</Typography>
                        <Form.Item
                            className={styles.inputWrite}
                            name={'vehicle.price'}
                        >
                            <Input disabled={true}/>
                        </Form.Item>
                    </Row>
                    <Row className={styles.row}>
                        <Typography className={styles.labelRow}>Подтверждающее цену фото</Typography>
                        <Image style={{height: '45px', width: '45px'}}
                               src={applicationEditState.application?.vehicle.documents[0]?.url}/>
                    </Row>
                </Card>
                <br/>
                <Card style={{width: '100%'}} bordered={true}>
                    <Title level={3}>Информация о клиенте</Title>
                    <Row>
                        <Typography className={styles.labelRow}>Имя*</Typography>
                        <Form.Item
                            className={styles.inputWrite}
                            name={'firstname'}
                            required={true}
                            rules={[
                                {type: "string", required: true, message: 'Укажите имя'},
                                {
                                    pattern: /^[а-яА-ЯёЁa-zA-Z\'\-\ ]+$/,
                                    message: 'Имя должно содержать только русские или английские буквы, а также символы “-” (дефис), “\'“ (верхний апостроф)'
                                }]}
                        >
                            <Input disabled={view || under}/>
                        </Form.Item>
                    </Row>
                    <Row>
                        <Typography className={styles.labelRow}>Фамилия*</Typography>
                        <Form.Item
                            className={styles.inputWrite}
                            name={'lastname'}
                            required={true}
                            rules={[
                                {type: "string", required: true, message: 'Укажите фамилию'},
                                {
                                    pattern: /^[а-яА-ЯёЁa-zA-Z\'\-\ ]+$/,
                                    message: 'Фамилия должно содержать только русские или английские буквы, а также символы “-” (дефис), “\'“ (верхний апостроф)'
                                }
                            ]}
                        >
                            <Input disabled={view || under}/>
                        </Form.Item>
                    </Row>
                    <Row>
                        <Typography className={styles.labelRow}>Отчество</Typography>
                        <Form.Item
                            className={styles.inputWrite}
                            name={'patronymic'}
                            required={false}
                            rules={[{
                                pattern: /^[а-яА-ЯёЁa-zA-Z\'\-\ ]+$/,
                                required: false,
                                message: 'Отчество должно содержать только русские или английские буквы, а также символы “-” (дефис), “\'“ (верхний апостроф)'
                            }]}
                        >
                            <Input disabled={view || under}/>
                        </Form.Item>
                    </Row>
                    <Row>
                        <Typography className={styles.labelRow}>Дата рождения*</Typography>
                        <Form.Item
                            name={'passport.date_of_birth'}
                            required={true}
                            rules={[{
                                type: "string",
                                required: true,
                                message: 'Дата указана не верно',
                                validator: (_, value) => {
                                    const date = moment(value, 'DD.MM.YYYY')
                                    if (date.isBefore(new Date())) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject('Дата указана не верно');
                                }
                            }]}
                        >
                          <DatePicker locale={locale} disabled={view || under} format={"DD.MM.YYYY"} />
                        </Form.Item>
                    </Row>
                    <Row>
                        <Typography className={styles.labelRow}>Пол*</Typography>
                        <Form.Item
                            className={styles.inputShort}
                            name={'gender'}
                            rules={[{type: "string", required: true, message: 'Укажите пол'}]}
                        >
                            <Select
                                disabled={view || under}
                                allowClear
                                placeholder="Выберите пол"
                            >
                                <Option value={"M"} key={"m"}>Мужской</Option>
                                <Option value={"W"} key={"w"}>Женский</Option>
                            </Select>
                        </Form.Item>
                    </Row>
                    <Row>
                        <Typography className={styles.labelRow}>Место рождения*</Typography>
                        <Form.Item
                            className={styles.inputWrite}
                            name={'passport.place_of_birth'}
                            required={true}
                            rules={[{type: "string", required: true, message: 'Укажите место рождения'}]}
                        >
                            <Input disabled={view || under}/>
                        </Form.Item>
                    </Row>
                    <Row>
                        <Typography className={styles.labelRow}>Серия и номер паспорта*</Typography>
                        <Form.Item
                            className={styles.inputPas}
                            name={'passport.series'}
                            required={true}
                            rules={[{type: "string", required: true, message: 'Укажите серию паспорта'}]}
                        >
                            <MaskedInput disabled={view || under} mask="1111"/>
                        </Form.Item>
                        <Form.Item
                            className={styles.inputPas}
                            name={'passport.number'}
                            required={true}
                            rules={[{type: "string", required: true, message: 'Укажите номер паспорта'}]}
                        >
                            <MaskedInput disabled={view || under} mask="111111"/>
                        </Form.Item>
                    </Row>
                    <Row>
                        <Typography className={styles.labelRow}>Паспорт выдан*</Typography>
                        <Form.Item
                            className={styles.inputWrite}
                            name={'passport.issuer'}
                            required={true}
                            rules={[{type: "string", required: true, message: 'Укажите кем выдан паспорт'}]}
                        >
                            <Input.TextArea rows={1} disabled={view || under}/>
                        </Form.Item>
                    </Row>
                    <Row>
                        <Typography className={styles.labelRow}>Дата выдачи*</Typography>
                        <Form.Item
                            className={styles.inputShort}
                            name={'passport.issued_at'}
                            required={true}
                            rules={[{
                                type: "string",
                                required: true,
                                message: 'Дата указана не верно',
                                validator: (_, value) => {
                                    const date = moment(value, 'DD.MM.YYYY')
                                    if (date.isBefore(new Date())) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject('Дата указана не верно');
                                }
                            }]}

                        >
                            <DatePicker locale={locale} disabled={view || under} format={"DD.MM.YYYY"} />
                            {/*<MaskedInput disabled={view || under} mask="11.11.1111"/>*/}
                        </Form.Item>
                    </Row>
                    <Row>
                        <Typography className={styles.labelRow}>Код подразделения*</Typography>
                        <Form.Item
                            className={styles.inputShort}
                            name={'passport.department_code'}
                            required={true}
                            rules={[{type: "string", required: true, message: 'Укажите код подразделения'}]}
                        >
                            <MaskedInput disabled={view || under} mask="111-111"/>
                        </Form.Item>
                    </Row>
                    <Row>
                        <Typography className={styles.labelRow}>Место регистрации*</Typography>
                        <Form.Item
                            className={styles.inputWrite}
                            name={'passport.registration'}
                            required={true}
                            rules={[{type: "string", required: true, message: 'Укажите место регистрации'}]}
                        >
                            <Input.TextArea rows={1} disabled={view || under}/>
                        </Form.Item>
                    </Row>
                </Card>
                <br/>
                <Card>
                    <Row>
                        <Typography className={styles.labelRow}>Описание ТС{under && '*'}</Typography>
                        <Form.Item
                            className={styles.inputWrite}
                            name={'vehicle.description'}
                            rules={[{type: "string", required: under, message: 'Укажите описание ТС'}]}
                            required={under}
                        >
                            <Input.TextArea rows={1} disabled={view || !under}/>
                        </Form.Item>
                    </Row>
                    <Row>
                        <Typography className={styles.labelRow}>Год приобретения</Typography>
                        <Form.Item
                            className={styles.inputShort}
                            name={'vehicle.acquisition_year'}
                        >
                            <MaskedInput mask="1111" disabled={view || under}/>
                        </Form.Item>
                    </Row>
                </Card>
                {disapproved && (
                    <>
                        <br/>
                        <Row>
                            <Typography className={styles.labelRow}>Причина отклонения</Typography>
                            <Form.Item
                                className={styles.inputWrite}
                                name={'registration_request.disapprove_reason'}
                            >
                                <Input disabled={true}/>
                            </Form.Item>
                        </Row>
                        <Row>
                            <Typography className={styles.labelRow}>Можно вернуть на доработку</Typography>
                            <Form.Item
                                className={styles.inputWrite}
                                name={'registration_request.can_be_returned'}
                            >
                                <Checkbox disabled={true}
                                          checked={applicationEditState.application.registration_request.can_be_returned}/>
                            </Form.Item>
                        </Row>
                        <Row>
                            <Typography className={styles.labelRow}>Дата отклонения</Typography>
                            <Form.Item
                                className={styles.inputShort}
                                name={'registration_request.disapproved_at'}
                            >
                                <MaskedInput disabled={true} mask="11.11.1111"/>
                            </Form.Item>
                        </Row>
                    </>
                )}
                <br/>
                <>
                    <Title level={3}>История изменений</Title>
                    <Col>
                        <div style={{height: 300, overflowY: 'scroll', paddingRight: 30, marginBottom: 20}}>
                            <Table pagination={false} rowKey={"id"}
                                   dataSource={applicationEditState.applicationSnapshots}>
                                <Column title="Поле" dataIndex="field" key="field" width={30}/>
                                <Column title="Старое значение" dataIndex="old_value" key="old_status"/>
                                <Column title="Новое значение" dataIndex="new_value" key="new_status"/>
                                <Column title="Дата и время изменений" dataIndex="created_at" key="created_at"/>
                                <Column title="Имя пользователя" dataIndex="changer_user_name" key="changer_user_name"/>
                                <Column title="Email" dataIndex="changer_user_email" key="changer_user_email"/>
                            </Table>
                        </div>
                    </Col>
                </>
                {!view && (
                    <>
                        <Row justify={"end"}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={applicationEditState.loading}>
                                    Сохранить
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type='ghost' style={{marginLeft: 10}} onClick={() => {
                                    history(-1)
                                }} loading={applicationEditState.loading}>
                                    Отменить
                                </Button>
                            </Form.Item>
                        </Row>
                    </>
                )}
            </Form>
        </>
    );
});
