import {makeAutoObservable, runInAction} from "mobx";
import { Policy } from "../../types/policy";
import {ClientProvider} from "../../utils/apiClient/clientProvider";
import moment from "moment";

export class PolicyViewState {
    loading: boolean = false;

    setLoading = (v: boolean) => this.loading = v;
    public policy?: Policy;

    constructor() {
        makeAutoObservable(this);
    }

    insuranceRiskTypeName: any = {
        "theft": "Угон транспорта",
        "destruction": "Полная гибель транспорта",
        "damage": "Повреждения",
        "health": "Страхование своей жизни и здоровья",
        "civil": "Страхование своей гражданской отвественности"
    }

    policyType: any = {
        hourly: "Почасовой",
        daily: "Посуточный"
    }

    payedType: any = {
        succeeded: "Оплачено",
    }

    async get( id?: string){
        try {
            this.setLoading(true);
            const res = await ClientProvider.authorized.get(`/policy/${id}`);
            if ( !res.data.errors ) {
                runInAction(() => {
                    this.policy = {} as Policy;
                    for ( const key in res.data ) {
                        (this.policy as any)[key] = res.data[key];
                    }
                    try {
                        this.policy.begins_at = moment(res.data.begins_at).format('DD.MM.YYYY HH:mm');
                        this.policy.ends_at =  moment(res.data.ends_at).format('DD.MM.YYYY HH:mm');
                        if ( res.data?.payment?.created_at ) {
                            this.policy.payment.created_at =  moment(res.data.payment.created_at).format('DD.MM.YYYY HH:mm');
                        }
                        if ( res.data.passport.date_of_birth ) {
                            this.policy.passport.date_of_birth = moment(res.data.passport.date_of_birth).format('DD.MM.YYYY');
                        }
                        if ( res.data.passport.issued_at ) {
                            this.policy.passport.issued_at = moment(res.data.passport.issued_at).format('DD.MM.YYYY');
                        }
                        this.policy.active = res.data.is_active? 'Действующий' : 'Истекший';
                        if ( res.data.passport.series && res.data.passport.number ) {
                            this.policy.passport.series_number = `${this.policy.passport.series} ${this.policy.passport.number}`;
                        }
                        this.policy.type = this.policyType[this.policy.type];
                        this.policy.payment.status = this.payedType[this.policy.payment.status];
                        this.policy.price = (+this.policy.price + +this.policy.civil_bonus_separately + +this.policy.health_bonus_separately).toFixed(2);
                    } catch (e) {
                        console.error(e)
                    }
                })
            } else {
                console.error(res);
            }
        } finally {
            this.setLoading(false);
        }
    }
}


export const policyViewState: PolicyViewState = new PolicyViewState();
