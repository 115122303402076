import {makeAutoObservable, runInAction} from "mobx";
import {
    AuthenticationDetails, CognitoUser,
    CognitoUserPool
} from 'amazon-cognito-identity-js';
import {message} from "antd";

class LoginState {
    private _email: string = '';
    private _password: string = '';
    private _loginResponse?: LoginResponseDto;
    private _loading: boolean = false;

    get loading(): boolean {
        return this._loading;
    }

    set loading(value: boolean) {
        this._loading = value;
    }

    get loginResponse(): LoginResponseDto | undefined {
        return this._loginResponse;
    }

    set loginResponse(value: LoginResponseDto | undefined) {
        this._loginResponse = value;
    }

    get password(): string {
        return this._password;
    }

    set password(value: string) {
        this._password = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    constructor() {
        makeAutoObservable(this);
    }

    async tryLogin() {
        runInAction(() => {
            try {
                this._loading = true;

                const userPool = new CognitoUserPool({
                    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
                    ClientId: process.env.REACT_APP_COGNITO_APP_ID!
                });
                const authenticationDetails = new AuthenticationDetails({
                    Username: this._email,
                    Password: this._password
                });
                const cognitoUser = new CognitoUser({
                    Username: this._email,
                    Pool: userPool
                });

                cognitoUser.authenticateUser(authenticationDetails, {
                    onSuccess: (res => {
                        this._loginResponse = {
                            accessToken: res.getAccessToken().getJwtToken(),
                            refreshToken: res.getRefreshToken().getToken()
                        }
                        this._loading = false;
                    }),
                    onFailure: (e => {
                        console.log(e);
                        this._loading = false;
                        message.error('Нет доступа');
                    }),
                })
            } catch (e) {
                console.log(e);
                this._loading = false;
            }
        });
    }
}

interface LoginResponseDto {
    accessToken: string;
    refreshToken: string;
}

export const loginState: LoginState = new LoginState();
