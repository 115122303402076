import {Col, Dropdown, Layout, Menu, Row, Spin} from 'antd';
import {Link, Outlet, useLocation, useNavigate} from 'react-router-dom';
import styles from './index.module.css';
import React, {useEffect, useState} from "react";
import {
  BookOutlined,
  FileProtectOutlined,
  FileTextOutlined,
  HomeOutlined,
  TeamOutlined,
  TranslationOutlined,
  UserOutlined
} from "@ant-design/icons";
import {usersListState} from "../../state/users/users-list.state";
import DownOutlined from "@ant-design/icons/lib/icons/DownOutlined";
import {MenuInfo} from "rc-menu/lib/interface";
import SubMenu from 'antd/lib/menu/SubMenu';
import {Observer} from 'mobx-react';
import {dictionaryListState} from "../../state/dictionary/dictionary-list.state";
import {dictionaryEditState} from "../../state/dictionary/dictionary-edit.state";
import {storage} from "../../utils/storage";

const {Header, Sider, Content} = Layout;

export const AdminLayout = () => {
  const history = useLocation();
  const [visible, setVisible] = useState(false);
  const route = useNavigate();

  const getDefaultSelectedKeys = () => {
    const p = history.pathname;
    if (p === '/') {
      return ['home'];
    }
    if (p === '/operator-applications') {
      return ['operator-applications']
    }
    if (p === '/under-applications') {
      return ['under-applications']
    }
    if (p === '/my-applications') {
      return ['my-applications']
    }
    if (p === '/rejected-applications') {
      return ['rejected-applications']
    }
    if (p === '/vehicle-model') {
      return ['/vehicle-model']
    }
    if (p === '/vehicle-type') {
      return ['/vehicle-type']
    }
    if (p === '/vehicle-brand') {
      return ['/vehicle-brand']
    }
  };

  const onExit = () => {
    localStorage.clear();
    window.location.href = '/login';
  }

  const handleVisibleChange = () => {
    setVisible(!visible);
  };

  const handleMenuClick = (e: MenuInfo) => {
    if (e.key === '1') {
      route(`/users/me?profile=true`)
      setVisible(!visible);
    }
    if (e.key === '2') {
      onExit();
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">Профиль</Menu.Item>
      <Menu.Item style={{color: "#ff0000"}} key="2">Выход</Menu.Item>
    </Menu>
  );

  if (!usersListState.currentUser) {
    return <Row justify={"center"} style={{marginTop: 20}} align="middle"><Spin/></Row>;
  }

  return (
    <Layout style={{height: '100vh'}}>
      <Sider style={{background: "#ffffff"}} width={250}>
        <div style={{width: 250, marginTop: '60px'}}>
          <Observer>
            {
              () => <Menu
                defaultSelectedKeys={getDefaultSelectedKeys()}
                mode="inline"
                theme="light"
                defaultOpenKeys={history.pathname.includes('application') ? ['applications'] : undefined}
              >
                <Menu.Item key="home">
                  <Link to={'/'}>
                    <HomeOutlined/> Главная
                  </Link>
                </Menu.Item>
                {
                  usersListState.currentUser?.role.includes("ADMIN") && (
                    <>
                      <Menu.Item key="users">
                        <Link to={'/users'}>
                          <UserOutlined/> Сотрудники
                        </Link>
                      </Menu.Item>
                    </>
                  )
                }
                <SubMenu key="applications" icon={<FileTextOutlined/>} title={`Заявки на регистрацию`}>
                  {usersListState.currentUser?.role.includes("UNDER") === true && (
                    <>
                      <Menu.Item key="under-applications"> <Link to={'/under-applications'}>Очередь
                        андеррайтеров </Link></Menu.Item>
                    </>
                  )}
                  {usersListState.currentUser?.role.includes("OPERATOR") === true && (
                    <>
                      <Menu.Item key="operator-applications"> <Link to={'/operator-applications'}>Очередь
                        операторов</Link></Menu.Item>
                    </>
                  )}
                  {usersListState.currentUser?.role.indexOf("ADMIN") === -1 && (
                    <>
                      <Menu.Item key="my-applications"><Link to={'/my-applications'}>Мои заявки</Link></Menu.Item>
                    </>
                  )}
                  <Menu.Item key="rejected-applications"><Link to={'/rejected-applications'}>Отклоненные
                    заявки</Link></Menu.Item>
                </SubMenu>
                <Menu.Item key="clients">
                  <Link to={'/clients'}>
                    <TeamOutlined/> Клиенты
                  </Link>
                </Menu.Item>
                <Menu.Item key="policy">
                  <Link to={'/policy'}>
                    <FileProtectOutlined/> Полисы
                  </Link>
                </Menu.Item>
                <Menu.Item key="policy-demo">
                  <Link to={'/policy-demo'}>
                    <FileProtectOutlined/> Демо полисы
                  </Link>
                </Menu.Item>
                <SubMenu key="dictionary" icon={<BookOutlined/>} title={`Cправочники`}>
                  <Menu.Item key="vehicle-type"><Link to={'/vehicle-type'}>Тип ТС</Link></Menu.Item>
                  <Menu.Item key="vehicle-brand"><Link to={'/vehicle-brand'}>Марки ТС</Link></Menu.Item>
                  <Menu.Item key="vehicle-model"><Link to={'/vehicle-model'}>Модели ТС</Link></Menu.Item>
                  <Menu.Item key="rate-days"><Link to={'/rate-daily'}>Суточные коэффициенты</Link></Menu.Item>
                  <Menu.Item key="rate-hourly"><Link to={'/rate-hourly'}>Часовые коэффициенты</Link></Menu.Item>
                </SubMenu>
                {
                  usersListState.currentUser?.role.includes("ADMIN") && (
                    <>
                      <Menu.Item key="localization">
                        <Link to={'/localization'}>
                          <TranslationOutlined/> Локализация
                        </Link>
                      </Menu.Item>
                    </>
                  )
                }
              </Menu>
            }
          </Observer>
        </div>
      </Sider>
      <Layout>
        <Observer>
          {() => (
            <Header style={{background: "#ffffff", textAlign: "right"}}>
              {usersListState.currentUser && (
                <Dropdown
                  overlay={menu}
                  onVisibleChange={handleVisibleChange}
                  visible={visible}
                >
                  <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    {usersListState.currentUser.name} <DownOutlined/>
                  </a>
                </Dropdown>
              )}
            </Header>
          )}
        </Observer>
        <Content className={styles.content}>
          <Outlet/>
        </Content>
      </Layout>
    </Layout>
  );
}
