// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".policy-form_inputWrite__1TucS{\n    width: 50%;\n}\n.policy-form_inputRisks__NGiEO{\n    width: 50%;\n    height: 70px;\n}\n.policy-form_inputShort__15e1j{\n    width: 17%;\n}\n.policy-form_row__2CYvS{\n    width: 100%;\n    padding-bottom: 10px;\n}\n.policy-form_labelRow__3hCcx{\n    padding-right: 10px;\n    width: 20%\n}\n.policy-form_inputPas__8ycZT{\n    width: 9%;\n    padding-right: 10px;\n}", "",{"version":3,"sources":["webpack://src/views/policy/components/policy-form/index.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;AACA;IACI,UAAU;IACV,YAAY;AAChB;AACA;IACI,UAAU;AACd;AACA;IACI,WAAW;IACX,oBAAoB;AACxB;AACA;IACI,mBAAmB;IACnB;AACJ;AACA;IACI,SAAS;IACT,mBAAmB;AACvB","sourcesContent":[".inputWrite{\n    width: 50%;\n}\n.inputRisks{\n    width: 50%;\n    height: 70px;\n}\n.inputShort{\n    width: 17%;\n}\n.row{\n    width: 100%;\n    padding-bottom: 10px;\n}\n.labelRow{\n    padding-right: 10px;\n    width: 20%\n}\n.inputPas{\n    width: 9%;\n    padding-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputWrite": "policy-form_inputWrite__1TucS",
	"inputRisks": "policy-form_inputRisks__NGiEO",
	"inputShort": "policy-form_inputShort__15e1j",
	"row": "policy-form_row__2CYvS",
	"labelRow": "policy-form_labelRow__3hCcx",
	"inputPas": "policy-form_inputPas__8ycZT"
};
export default ___CSS_LOADER_EXPORT___;
