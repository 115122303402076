import React, {useEffect} from "react";
import {Button, Card, Col, Form, Image, Input, Row} from 'antd';
import {observer} from "mobx-react";
import {autorun} from "mobx";
import {useNavigate} from "react-router-dom";
import styles from './index.module.css';
import Title from "antd/lib/typography/Title";
import {forgetState} from "../../../../state/login/forget.state";

const ForgetPage = observer(() => {
    const history = useNavigate();
    useEffect(() => {
        forgetState.success = false;
        return autorun(() => {
            if ( forgetState.success ) {
                history('/restore');
            }
        });
    }, [history]);

    return (
        <>
            <Col md={{span: 6, offset: 9}}>
                <Row align={'middle'} justify={'center'} style={{height: '100vh'}}>
                    <Form
                        className={styles.form}
                        style={{width: '100%'}}
                        layout={'vertical'}
                        onFinish={() => forgetState.requestReset()}
                    >
                        <Image
                            className={styles.logo}
                            preview={false}
                            width={150}
                            src="/assets/images/text_logo.png"
                        />
                        <Card style={{width: 360}} bordered={true}>
                            <Title level={5} style={{textAlign: 'center'}}>
                                Забыли пароль?
                            </Title>
                            <Form.Item
                                label={'Логин'}
                                name={'email'}
                                rules={[{required: true, message: 'Укажите почту'}]}
                            >
                                <Input
                                    type="email"
                                    value={forgetState.email}
                                    onChange={e => forgetState.email = (e.target.value)}
                                />
                            </Form.Item>
                            <Row className={styles.button} >
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" loading={forgetState.loading} >
                                        Отправить код на сброс пароля
                                    </Button>
                                </Form.Item>
                            </Row>
                        </Card>
                    </Form>
                </Row>
            </Col>
        </>
    );
});

export default ForgetPage;
