import {observer} from "mobx-react";
import { UserForm} from "../../components/user-form";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import { userEditState } from "../../../../state/users/user-edit.state";

export const UserAddPage = observer(() => {
  const history = useNavigate();
  useEffect(() => {
    userEditState.reset();
  }, []);

  return (
    <>
      <UserForm
        add={true}
        onSave={() => history('/users', {replace: true})}
      />
    </>
  )
});

