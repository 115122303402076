import {observer} from "mobx-react";
import {Button, Col, Row, Table} from "antd";
import React from "react";
import {useNavigate} from "react-router-dom";
import Column from "antd/lib/table/Column";
import {Application} from "../../../../types/application";
import {applicationsListState} from "../../../../state/application/applications-list.state";

interface Props {
  applications: Application[];
  location: string;
}

export const ApplicationTable = observer(({applications, location}: Props) => {
  const navigator = useNavigate();

  const renderActions = (v: any, record: Application) => {
    const isOperatorList = location.includes('operator');
    const isUnderwriterList = location.includes('under');
    const onClick = async () => {
      if ( isOperatorList ) {
        await applicationsListState.startBid(record.id);
      } else if ( isUnderwriterList ) {
        await applicationsListState.startBidUnderwriter(record.id);
      }
      navigator(`${location}/${record.id}`)
    }

    return (
      <Row justify={"space-around"}>
        <Button type="primary" onClick={onClick}>
          {isOperatorList || isUnderwriterList ? 'Взять в работу' : 'Продолжить'}
        </Button>
      </Row>
    );
  }

  return (
    <>
      <Col>
        <Table rowKey={"id"} dataSource={applications}>
          <Column title="Id" dataIndex="id" key="id" width={330}/>
          <Column title="Номер телефона пользователя" dataIndex="phone_number" key="phone_number"/>
          <Column title="Дата и время создания" dataIndex="created_at" key="created_at"/>
          <Column title="Статус заявки" dataIndex="status_readable" key="status_readable"/>
          <Column title="Действия" render={renderActions}/>
        </Table>
      </Col>
    </>
  );
});
