import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import LoginPage from "./views/login/pages";
import 'antd/dist/antd.css';
import {systemState} from "./state/system.state";
import {autorun} from "mobx";
import {AdminLayout} from "./views/layout";
import {UsersListPage} from "./views/users/pages/list";
import {UserAddPage} from "./views/users/pages/add";
import {UserEditPage} from "./views/users/pages/edit";
import {LocalizationListPage} from "./views/localization/pages/list";
import HomePage from './views/home/pages';
import ForgetPage from './views/login/pages/forget';
import {ListPage} from "./views/applications/pages/list";
import { ApplicationEditPage } from './views/applications/pages/edit';
import RecoveryLoginPage from "./views/login/pages/restore";
import { RejectedPage } from './views/applications/pages/rejected';
import {PolicyPage} from "./views/policy/list";
import { ClientListPage } from './views/clients/pages/list';
import {usersListState} from "./state/users/users-list.state";
import {PolicyViewPage} from "./views/policy/view";
import {ClientEditPage} from "./views/clients/pages/edit";
import {DictionaryListPage} from './views/dictionary/pages/list';
import {DictionaryEditPage} from "./views/dictionary/pages/edit";
import {dictionaryListState} from "./state/dictionary/dictionary-list.state";

function App() {
  const [currentUser, setCurrentUser] = useState(false);
  useEffect(() => {
    return autorun(() => {
      if (usersListState.currentUser){
        setCurrentUser(true);
      }
      if (!systemState.authToken) {
        window.location.href = '/login';
      }
    });
  });

  useEffect(() => {
    if ( window.location.pathname !== '/login' && systemState.authToken ) {
      usersListState.getAuthUser();
      dictionaryListState.getDictionaryList();
    }
  }, [])

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage/>}/>
          <Route path="/forget" element={<ForgetPage/>}/>
          <Route path="/restore" element={<RecoveryLoginPage/>}/>
          <Route path={'/'} element={<AdminLayout/>}>
            <Route index element={<HomePage />} />
            {usersListState.currentUser?.role.includes("ADMIN") && (
              <>
                <Route path={'/users'} element={<UsersListPage/>} />
                <Route path={'/users/add'} element={<UserAddPage/>} />
                <Route path={'/users/:id'} element={<UserEditPage/>} />
              </>
            )}
            <Route path={'/users/me'} element={<UserEditPage/>} />
            <Route path={'/localization'} element={<LocalizationListPage/>} />
            {(usersListState.currentUser?.role.includes("OPERATOR") === true ||
            usersListState.currentUser?.role.includes("ADMIN") === true )
            &&(
                <>
                  <Route path={'/operator-applications'} element={<ListPage/>} />
                  <Route path={'/operator-applications/:id'} element={<ApplicationEditPage/>} />
                </>
            )}
            {(usersListState.currentUser?.role.includes("UNDER") === true ||
                usersListState.currentUser?.role.includes("ADMIN") === true )&&(
              <>
                <Route path={'/under-applications'} element={<ListPage/>} />
                <Route path={'/under-applications/:id'} element={<ApplicationEditPage/>} />
              </>
            )}
            <Route path={'/applications/:id'} element={<ApplicationEditPage/>} />
            <Route path={'/rejected-applications'} element={<RejectedPage/>} />
            <Route path={'/rejected-applications/:id'} element={<ApplicationEditPage/>} />
            {
              !usersListState.currentUser?.role.includes("ADMIN") &&
              (
              <>
                <Route path={'/my-applications'} element={<ListPage/>} />
                <Route path={'/my-applications/:id'} element={<ApplicationEditPage/>} />
              </>
              )
            }
            <Route path={'/clients'} element={<ClientListPage/>} />
            <Route path={'/client/:id'} element={<ClientEditPage/>} />

            <Route path={'/policy-demo'} element={<PolicyPage demo={true}/>} />
            <Route path={'/policy'} element={<PolicyPage/>} />
            <Route path={'/policy/:id'} element={<PolicyViewPage/>} />

            <Route path={'/vehicle-type'} element={<DictionaryListPage/>} />
            <Route path={'/vehicle-type/:id'} element={<DictionaryEditPage/>} />

            <Route path={'/vehicle-brand'} element={<DictionaryListPage/>} />
            <Route path={'/vehicle-brand/:id'} element={<DictionaryEditPage/>} />

            <Route path={'/vehicle-model'} element={<DictionaryListPage/>} />
            <Route path={'/vehicle-model/:id'} element={<DictionaryEditPage/>} />

            <Route path={'/rate-daily'} element={<DictionaryListPage/>} />
            <Route path={'/rate-daily/:id'} element={<DictionaryEditPage/>} />

            <Route path={'/rate-hourly'} element={<DictionaryListPage/>} />
            <Route path={'/rate-hourly/:id'} element={<DictionaryEditPage/>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
