import {observer} from "mobx-react";
import {Button, Form, Input, InputNumber, message, Row, Select, Spin, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {autorun} from "mobx";
import {FieldData} from "rc-field-form/lib/interface";
import {useNavigate} from "react-router-dom";
import {dictionaryEditState} from "../../../../state/dictionary/dictionary-edit.state";
import styles from "../../../applications/components/application-form/index.module.css";
import {get, set} from 'lodash';

const {Option} = Select;

interface Props {
  onSave: () => void;
  view?: boolean;
  add?: boolean;
  location?: string;
}

export const VehicleTermForm = observer(({onSave, location, view, add}: Props) => {
  const history = useNavigate();
  const [gotBrands, setGotBrands] = useState(false);

  useEffect(() => {
    return autorun(() => {
      if (dictionaryEditState.saveResponse) {
        onSave();
        message.success("Сохранено");
        dictionaryEditState.saveResponse = undefined;
      }
    });
  });
  useEffect(() => {
    dictionaryEditState.getParentsForEditForm('vehicle_types', 'types');
  }, []);
  useEffect(() => {
    if (dictionaryEditState.term?.parent?.parent?.id && !gotBrands) {
      setGotBrands(true);
      dictionaryEditState.getParentsForEditForm('vehicle_brands', 'brands', dictionaryEditState.term?.parent?.parent?.id);
    }
  }, [dictionaryEditState.term])


  if (!dictionaryEditState.term) {
    return <Spin/>;
  }

  let fields: FieldData[];
  if (location?.includes('vehicle-type')) {
    fields = ['name'].map(e => ({
      name: e,
      value: get((dictionaryEditState as any).term, e)
    }));
  } else if (location?.includes('vehicle-brand')) {
    fields = ['name', 'parent.name', 'parent.id'].map(e => ({
      name: e,
      value: get((dictionaryEditState as any).term, e)
    }));
  } else if (location?.includes('rate-daily') || location?.includes('rate-hourly')) {
    fields = ['name', 'data.coefficient'].map(e => ({
      name: e,
      value: get((dictionaryEditState as any).term, e)
    }));
  }  else {
    fields = ['name', 'parent.id', 'parent.name', 'parent.parent.id', 'parent.parent.name', 'data.full_name', 'data.tariff'].map(e => ({
      name: e,
      value: get((dictionaryEditState as any).term, e)
    }));
  }

  const getBrandOptions = (typeVal: string) => {
    if (location?.includes('vehicle-model')) {
      set((dictionaryEditState as any).term, 'parent.id', undefined);
      set((dictionaryEditState as any).term, 'parent.name', undefined);
      dictionaryEditState.getParentsForEditForm('vehicle_brands', 'brands', typeVal);
    }
  }

  const renderTypeSelect = () => {
    if (view) {
      return <Input disabled/>
    }
    return (
      <Select
        disabled={view}
        allowClear
        placeholder="Выберите тип"
        onChange={getBrandOptions}
      >
        {
          dictionaryEditState.vehicleEditFormTerms.types.map(e => (
            <Option value={e.id} key={e.id}>{e.name}</Option>
          ))
        }
      </Select>
    );
  }

  const renderBrandSelect = () => {
    if (view || !dictionaryEditState.vehicleEditFormTerms.brands.length) {
      return <Input disabled/>
    }
    return (
      <Select
        disabled={view}
        allowClear
        placeholder="Выберите марку ТС"
      >
        {
          dictionaryEditState.vehicleEditFormTerms.brands.map(e => (
            <Option value={e.id} key={e.id}>{e.name}</Option>
          ))
        }
      </Select>
    )
  }

  return (
    <>
      <Form
        style={{width: '100%'}}
        layout={'vertical'}
        onFinish={() => dictionaryEditState.trySave(location!)}
        fields={fields}
        onFieldsChange={(f) => {
          const [v] = f;
          const name = (v.name as any[])[0];
          (dictionaryEditState as any).term[name] = v.value;
        }}
      >
        {location?.includes('vehicle-type') && (
          <>
            <Row>
              <Typography className={styles.labelRow}>Тип ТС*</Typography>
              <Form.Item
                className={styles.inputWrite}
                name={'name'}
                required={true}
                rules={[{type: "string", required: true, message: 'Укажите тип ТС'}]}
              >
                <Input disabled={view}/>
              </Form.Item>
            </Row>
          </>
        )}
        {location?.includes('vehicle-brand') && (
          <>
            <Row>
              <Typography className={styles.labelRow}>Тип ТС*</Typography>
              <Form.Item
                className={styles.inputShort}
                name={view ? 'parent.name' : 'parent.id'}
                rules={[{type: "string", required: true, message: 'Укажите тип ТС'}]}
              >
                {renderTypeSelect()}
              </Form.Item>
            </Row>
            <Row>
              <Typography className={styles.labelRow}>Марка ТС*</Typography>
              <Form.Item
                className={styles.inputWrite}
                name={'name'}
                required={true}
                rules={[{type: "string", required: true, message: 'Укажите марку ТС'}]}
              >
                <Input disabled={view}/>
              </Form.Item>
            </Row>
          </>
        )}
        {location?.includes('vehicle-model') && (
          <>
            <Row>
              <Typography className={styles.labelRow}>Тип ТС*</Typography>
              <Form.Item
                className={styles.inputShort}
                name={view ? 'parent.parent.name' : 'parent.parent.id'}
                rules={[{type: "string", required: true, message: 'Укажите тип ТС'}]}
              >
                {renderTypeSelect()}
              </Form.Item>
            </Row>
            <Row>
              <Typography className={styles.labelRow}>Марка*</Typography>
              <Form.Item
                className={styles.inputShort}
                name={view ? 'parent.name' : 'parent.id'}
                rules={[{type: "string", required: true, message: 'Укажите марку ТС'}]}
              >
                {renderBrandSelect()}
              </Form.Item>
            </Row>
            <Row>
              <Typography className={styles.labelRow}>Модель*</Typography>
              <Form.Item
                className={styles.inputWrite}
                name={'name'}
                required={true}
                rules={[{type: "string", required: true, message: 'Укажите модель'}]}
              >
                <Input disabled={view}/>
              </Form.Item>
            </Row>
            <Row>
              <Typography className={styles.labelRow}>Полное название модели</Typography>
              <Form.Item
                className={styles.inputWrite}
                name={'data.full_name'}>
                <Input disabled={view}/>
              </Form.Item>
            </Row>
            <Row>
              <Typography className={styles.labelRow}>Тариф*</Typography>
              <Form.Item
                className={styles.inputWrite}
                name={'data.tariff'}
                required={true}
                rules={[{type: "string", required: true, message: 'Укажите тариф'}, {pattern: /^[+-]?([0-9]*[.])?[0-9]+$/, message: "Тариф должно быть числом с разделителем точкой" }]}
              >
                <Input disabled={view}/>
              </Form.Item>
            </Row>
          </>
        )}
        {(location?.includes('rate-daily') || location?.includes('rate-hourly')) && (
            <>
              <Row>
                <Typography className={styles.labelRow}>
                  {location?.includes('rate-daily') ? 'Сутки*' : 'Часы*'}
                </Typography>
                <Form.Item
                    className={styles.inputWrite}
                    name={'name'}
                    required={true}
                    rules={[{type: "string", required: true, message: 'Укажите период'}]}
                >
                  <Input disabled={view}/>
                </Form.Item>
              </Row>
              <Row>
                <Typography className={styles.labelRow}>Коэффициент*</Typography>
                <Form.Item
                    className={styles.inputWrite}
                    name={'data.coefficient'}
                    required={true}
                    rules={[{type: "string", required: true, message: 'Укажите коэффициент'}, {pattern: /^[+-]?([0-9]*[.])?[0-9]+$/, message: "Коэффициент должно быть числом с разделителем точкой" }]}
                >
                  <Input disabled={view}/>
                </Form.Item>
              </Row>
            </>
        )}

        {!view && (
          <Row>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={dictionaryEditState.loading}>
                Сохранить
              </Button>
            </Form.Item>
            <Form.Item>
              <Button type='ghost' style={{marginLeft: 10}} onClick={() => {
                history(-1)
              }} loading={dictionaryEditState.loading}>
                Отменить
              </Button>
            </Form.Item>
          </Row>
        )}
      </Form>
    </>
  );
});
