import {makeAutoObservable, runInAction, toJS} from "mobx";
import {ClientProvider} from "../../utils/apiClient/clientProvider";
import {Application} from "../../types/application";
import moment from "moment";
import {set} from "lodash";
import {applicationStatus} from "../../utils/application-statuses";
import {message} from "antd";

export class ApplicationEditState {
  loading: boolean = false;
  saveResponse?: Application;

  public application?: Application;
  public applicationSnapshots: ApplicationSnapshot[] = []
  public disapprovedBid: boolean = false;
  public movedBid: boolean = false;

  public triggeredToUnderwriter: Date | undefined;
  public triggeredConfirmed: Date | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading = (v: boolean) => this.loading = v;

  resetApplication() {
    this.application = undefined;
  }

  async trySave() {
    try {
      this.setLoading(true);
      const data: any = {};
      const application = toJS(this).application!;
      for (const key in application) {
        set(data, key, (application as any)[key]);
      }
      const res = await ClientProvider.authorized.put(`/bid/${application!.id}`, {
        passport: {
          date_of_birth: data.passport.date_of_birth.format('DD.MM.YYYY'),
          department_code: data.passport.department_code,
          issued_at: data.passport.issued_at.format('DD.MM.YYYY'),
          issuer: data.passport.issuer,
          number: data.passport.number,
          place_of_birth: data.passport.place_of_birth,
          registration: data.passport.registration,
          series: data.passport.series
        },
        vehicle: {
          description: data.vehicle.description,
          acquisition_year: data.vehicle.acquisition_year
        },
        gender: data.gender,
        firstname: data.firstname,
        lastname: data.lastname,
        patronymic: data.patronymic
      });
      if (!res.data.errors) {
        runInAction(() => {
          message.success("Изменения сохранены");
          this.saveResponse = res.data;
        });
      } else {
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }

  async get(id?: string) {
    try {
      this.movedBid = false;
      this.disapprovedBid = false;
      this.triggeredToUnderwriter = undefined;
      this.triggeredConfirmed = undefined;
      this.setLoading(true);
      const res = await ClientProvider.authorized.get(`/bid/${id}`);
      if (!res.data.errors) {
        runInAction(() => {
          this.application = {} as Application;
          for (const key in res.data) {
            (this.application as any)[key] = res.data[key];
          }
          this.application!.created_at = moment(res.data.created_at).format('DD.MM.YYYY HH:mm');
          this.application!.status_readable = applicationStatus[res.data.status];
          this.application!.passport.date_of_birth = res.data.passport.date_of_birth ? moment(res.data.passport.date_of_birth) : null;
          this.application!.passport.issued_at = res.data.passport.issued_at ? moment(res.data.passport.issued_at) : null;
          if (this.application.registration_request.disapproved_at) {
            this.application.registration_request.disapproved_at = moment(res.data.registration_request.disapproved_at).format('DD.MM.YYYY');
          }
          if (this.application.registration_request.approved_at) {
            this.application.registration_request.approved_at = moment(res.data.registration_request.approved_at).format('DD.MM.YYYY');
          }
          if ( this.application?.vehicle?.photos?.length === 2 ) {
            const ph = this.application.vehicle.photos;
            if ( ph[0].created_at && ph[1].created_at ) {
              this.application.vehicle.photos.sort(
                  (a, b) => moment(a.created_at).isBefore(moment(b.created_at)) ? -1 : 1
              );
            }
          }

          this.applicationSnapshots = this.application.bid_snapshots.map(e => {
            if (e.field === "passport.issued_at" || e.field === "passport.date_of_birth") {
              if (e.old_value) e.old_value = moment(e.old_value).format('DD.MM.YYYY');
              if (e.new_value) e.new_value = moment(e.new_value).format('DD.MM.YYYY');
            }
            return new ApplicationSnapshot(
              e.field, e.old_value, e.new_value, e.created_at, `${e.changer_user.lastname} ${e.changer_user.firstname} ${e.changer_user.patronymic}`, e.changer_user.email
            );
          })
        })
      } else {
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }

  async disapprove(id: string, reasonText: string, reasonCheck: boolean) {
    try {
      this.setLoading(true);
      const res = await ClientProvider.authorized.put(`/bid/${id}/disapprove`, {
        disapprove_reason: reasonText,
        can_be_returned: reasonCheck
      });
      if (res.status === 200) {
        message.success("Заявка отклонена");
        runInAction(() => {
          this.disapprovedBid = true;
        })
      } else {
        message.error("Ошибка. Возможно заявка находится не в том статусе");
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }

  async toUnderwriter(id: string) {
    try {
      this.setLoading(true);
      const res = await ClientProvider.authorized.put(`/bid/${id}/to-underwriter`);
      if (res.status === 200) {
        message.success("Заявка переведена");
        runInAction(() => {
          this.movedBid = true;
        })
      } else {
        message.error("Ошибка. Возможно заявка находится не в том статусе");
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }

  async confirm(id: string) {
    try {
      this.setLoading(true);
      const res = await ClientProvider.authorized.put(`/bid/${id}/confirm`);
      if (res.status === 200) {
        message.success("Заявка одобрена");
        runInAction(() => {
          this.movedBid = true;
        })
      } else {
        message.error("Ошибка. Возможно заявка находится не в том статусе");
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }

  triggerToUnderwriter() {
    this.triggeredToUnderwriter = new Date();
  }

  triggerConfirmed() {
    this.triggeredConfirmed = new Date();
  }
}

class ApplicationSnapshot {
  static fieldTranslations: {
    [key: string]: string
  } = {
    status: "Статус",
    firstname: "Имя",
    lastname: "Фамилия",
    patronymic: "Отчество",
    gender: "Пол",
    'registration_request.disapprove_reason': 'Причина отклонения',
    'registration_request.can_be_returned': 'Можно возвратить',
    "passport.place_of_birth": "Место рождения",
    "passport.date_of_birth": "Дата рождения",
    "passport.series": "Серия паспорта",
    "passport.number": "Номер паспорта",
    "passport.issuer": "Паспорт выдан",
    "passport.issued_at": "Дата выдачи",
    "passport.department_code": "Код подразделения",
    "passport.registration": "Место регистрации",
    "vehicle.description": "Описание ТС",
    "vehicle.acquisition_year": "Год приобритения"
  };
  static valueTranslations: {
    [key: string]: Map<string, string>
  } = {
    status: new Map<string, string>([
      ['inprogress', "В обработке"],
      ['overview', "На рассмотрении"],
      ['confirm', "Одобрена"],
      ['disapproved', "Отклонена"]
    ]),
    gender: new Map<string, string>([
      ["M", "Мужской"],
      ["W", "Женский"]
    ]),
    'registration_request.can_be_returned': new Map<string, string>([
      ['true', 'Да'],
      ['false', 'Нет']
    ])
  }

  constructor(
    public field: string,
    public old_value: string,
    public new_value: string,
    public created_at: string,
    public changer_user_name: string,
    public changer_user_email: string
  ) {
    this.old_value = ApplicationSnapshot.valueTranslations[this.field] ? ApplicationSnapshot.valueTranslations[this.field].get(old_value)! : old_value;
    this.new_value = ApplicationSnapshot.valueTranslations[this.field] ? ApplicationSnapshot.valueTranslations[this.field].get(new_value)! : new_value;
    this.field = ApplicationSnapshot.fieldTranslations[field] || field;
    this.created_at = moment(created_at).format("DD.MM.YYYY HH:mm");
  }
}


export const applicationEditState: ApplicationEditState = new ApplicationEditState();
