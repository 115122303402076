// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".application-form_inputWrite__2NUPa{\n    width: 50%;\n}\n.application-form_inputShort__rYeeR{\n    width: 17%;\n}\n.application-form_row__1cvuR{\n    width: 100%;\n    padding-bottom: 10px;\n}\n.application-form_labelRow__4J0VI{\n    padding-right: 10px;\n    width: 16%\n}\n.application-form_inputPas__fdA_w{\n    width: 9%;\n    padding-right: 10px;\n}", "",{"version":3,"sources":["webpack://src/views/applications/components/application-form/index.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;AACA;IACI,UAAU;AACd;AACA;IACI,WAAW;IACX,oBAAoB;AACxB;AACA;IACI,mBAAmB;IACnB;AACJ;AACA;IACI,SAAS;IACT,mBAAmB;AACvB","sourcesContent":[".inputWrite{\n    width: 50%;\n}\n.inputShort{\n    width: 17%;\n}\n.row{\n    width: 100%;\n    padding-bottom: 10px;\n}\n.labelRow{\n    padding-right: 10px;\n    width: 16%\n}\n.inputPas{\n    width: 9%;\n    padding-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputWrite": "application-form_inputWrite__2NUPa",
	"inputShort": "application-form_inputShort__rYeeR",
	"row": "application-form_row__1cvuR",
	"labelRow": "application-form_labelRow__4J0VI",
	"inputPas": "application-form_inputPas__fdA_w"
};
export default ___CSS_LOADER_EXPORT___;
