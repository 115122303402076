import {observer} from "mobx-react";
import {Button, Dropdown, Form, Input, Select, Checkbox, Divider, message} from "antd";
import React, {useEffect} from "react";
import {autorun} from "mobx";
import {FieldData} from "rc-field-form/lib/interface";
import {userEditState} from "../../../../state/users/user-edit.state";
import {useNavigate, useParams} from "react-router-dom";
import MaskedInput from "antd-mask-input";
import {EyeInvisibleOutlined, EyeTwoTone} from "@ant-design/icons";
const {Option} = Select;

interface Props {
  onSave: () => void;
  profile?: boolean;
  view?: boolean;
  add?: boolean
}

export const UserForm = observer(({onSave, profile, view, add}: Props) => {
  useEffect(() => {
    return autorun(() => {
      if (userEditState.saveResponse) {
        onSave();
        message.success("Сохранено");
        userEditState.saveResponse = undefined;
      }
    });
  });

  function handleChange(value: string) {
    if (value.includes('ADMIN') === true) {
      value = 'ADMIN';
    }
    userEditState.role = [value];
    console.log(`selected ${value}`);
  }

  const fields: FieldData[] = ['name', 'email', 'phone_number', 'role', 'password'].map( e => ({
    name: e,
    value: (userEditState as any)[e]
  }));
  return (
    <>
      <Form
        style={{width: '100%'}}
        layout={'vertical'}
        onFinish={() => userEditState.trySave()}
        fields={fields}
        onFieldsChange={(f) => {
          const [v] = f;
          const name = (v.name as any[])[0];
          (userEditState as any)[name] = v.value;
        }}
      >
        <Form.Item
            label={'ФИО'}
            name={'name'}
            rules={[{required: add, message: 'Укажите ФИО'}, {pattern: /^[а-яА-ЯёЁ\'\-\ ]+$/, message: 'Имя должно содержать только русские буквы, а также символы “-” (дефис), “\'“ (верхний апостроф)' }]}
        >
          <Input disabled={view} />
        </Form.Item>
        <Form.Item
            label={'Логин'}
            name={'email'}
            rules={[{required: add, message: 'Укажите логин'}, {pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: "Не верный логин" }]}
        >
          <Input autoComplete={'off'}  disabled={!add} />
        </Form.Item>
        <Form.Item
            label={'Телефон'}
            name={'phone_number'}
            rules={[{required: add, message: 'Укажите телефон'}]}
        >
          <MaskedInput mask="+7(111)-111-11-11" disabled={!add}/>
        </Form.Item>

        {add && (<Form.Item
            label={'Пароль'}
            name={'password'}
            rules={[{required: add, message: 'Укажите пароль'}, {pattern: /^(?!^[0-9]*$)(?!^[a-zA-Z]*$)^([a-zA-Z0-9]{8,15})$/, message: 'Пароль должен быть не менее 8 символов, должен содержать хотя бы одну цифру, а так же заглавную и строчную буквы.' }]}
        >
          <Input.Password min={8}
          iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>) }

        {
          !profile && (
              <Form.Item
                  label={'Роли'}
                  name={'role'}
                  rules={[{type: "array", required: add, message: 'Укажите роли'}]}
              >
                <Select
                    disabled={view}
                    style={{ width: '100%' }}
                    placeholder="Выберите роли"
                    value={userEditState.role[0]}
                    onChange={handleChange}
                >
                  <Option value={"OPERATOR"} key={"OPERATOR"}>Оператор</Option>
                  <Option value={"UNDER"} key={"UNDER"}>Андеррайтер</Option>
                  <Option value={"ADMIN"} key={"ADMIN"}>Администратор</Option>
                </Select>
                <br />
              </Form.Item>
          )
        }
        {!view && (
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={userEditState.loading}>
                Сохранить
              </Button>
            </Form.Item>
        )}
      </Form>
    </>
  );
});
