import {observer} from "mobx-react";
import {Button, Col, Row, Select, Table} from "antd";
import React, {useEffect} from "react";
import {autorun} from "mobx";
import {FieldData} from "rc-field-form/lib/interface";
import {userEditState} from "../../../../state/users/user-edit.state";
import {Link, useNavigate} from "react-router-dom";
import Column from "antd/lib/table/Column";
import {ListPolicy} from "../../../../types/list-policy";
import {EyeTwoTone} from "@ant-design/icons";

const {Option} = Select;

interface Props {
  policy: ListPolicy[];
}

export const PolicyTable = observer(({policy}: Props) => {
  const renderActions = (v: any, record: ListPolicy) => (
      <Row justify={"space-around"}>
          <Link to={`/policy/${record.id}?view=true`}>
              <EyeTwoTone
                  style={{fontSize: '2em'}}
              />
          </Link>
      </Row>
  );

  return (
    <>
      <Col>
        <Table dataSource={policy}>
          <Column title="Номер полиса" dataIndex="number" key="policy_number" width={140}/>
          <Column title="Тип полиса" dataIndex="type_readable" key="policy_type_readable" width={120}/>
          <Column title="Стоимость" dataIndex="price" key="policy_price" width={30}/>
          <Column title="Фамилия ЗЛ" dataIndex={["user","lastname"]} key="user.lastname"/>
          <Column title="Имя ЗЛ" dataIndex={["user","firstname"]} key="user.firstname"/>
          <Column title="Отчество ЗЛ" dataIndex={["user","patronymic"]} key="user.patronymic"/>
          <Column title="Номер телефона" dataIndex={["user","phone_number"]} key="user.phone_number" width={150}/>
          <Column title="Дата создания" dataIndex="created_at" key="created_at" width={140}/>
          <Column title="Действия" render={renderActions} width={30}/>
        </Table>
      </Col>
    </>
  );
});
