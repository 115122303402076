import React, {useEffect} from "react";
import {Button, Card, Col, Form, Image, Input, Row} from 'antd';
import {observer} from "mobx-react";
import {loginState} from "../../../state/login/login.state";
import {autorun} from "mobx";
import {systemState} from "../../../state/system.state";
import {useNavigate} from "react-router-dom";
import styles from './index.module.css';
import Title from "antd/lib/typography/Title";
import {EyeInvisibleOutlined, EyeTwoTone} from "@ant-design/icons";

const LoginPage = observer(() => {
  const history = useNavigate();
  useEffect(() => {
    return autorun(() => {
      if (loginState.loginResponse) {
        systemState.setAuthToken(loginState.loginResponse.accessToken as string);
        systemState.setRefreshToken(loginState.loginResponse.refreshToken as string);
        systemState.setEmail(loginState.email);
        window.location.href = '/';
      }
    });
  }, [history]);

  return (
    <>
      <Col md={{span: 6, offset: 9}}>
        <Row align={'middle'} justify={'center'} style={{height: '100vh'}}>
          <Form
            className={styles.form}
            style={{width: '100%'}}
            layout={'vertical'}
            onFinish={() => loginState.tryLogin()}
          >
            <Image
              className={styles.logo}
              preview={false}
              width={150}
              src="/assets/images/text_logo.png"
            />
            <Card style={{ width: 300 }} bordered={true}>
              <Title level={5} style={{textAlign: 'center'}}>
                Авторизация
              </Title>
              <Form.Item
                  label={'Логин'}
                  name={'email'}
                  rules={[{required: true, message: 'Укажите почту'}]}
              >
                <Input
                    type="email"
                    value={loginState.email}
                    onChange={e => loginState.email = (e.target.value)}
                />
              </Form.Item>
              <Form.Item
                  label={'Пароль'}
                  name={'password'}
                  rules={[{required: true, message: 'Укажите пароль'}]}
              >
                <Input.Password
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    type="password"
                    value={loginState.password}
                    onChange={e => loginState.password = (e.target.value)}
                />
              </Form.Item>
              <Row className={styles.button}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={loginState.loading}>
                    Вход
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" onClick={()=>{history(`/forget`)}} >
                    Забыли пароль?
                  </Button>
              </Form.Item>
              </Row>
            </Card>
          </Form>
        </Row>
      </Col>
    </>
  );
});

export default LoginPage;
