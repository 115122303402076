import {Checkbox, Col, DatePicker, message, Row, Select, Table} from "antd";
import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import {DeleteTwoTone, EditTwoTone, EyeTwoTone, FilterOutlined} from "@ant-design/icons";
import {usersListState} from "../../../../state/users/users-list.state";
import Popconfirm from "antd/es/popconfirm";
import {clientsListState} from "../../../../state/clients/clients-list.state";
import Title from "antd/lib/typography/Title";
import Search from "antd/lib/input/Search";
import {ListClient} from "../../../../types/list-client";

const {Column} = Table;
const { Option } = Select;

export const ClientListPage = observer(() => {
  const [visible, setVisible] = useState(false);
  const [typeId, setTypeId] = useState('');
  const [search, setSearch] = useState('');
  const [deletedCheck, setDeletedCheck] = useState(false);

  useEffect(() => {
    clientsListState.getList();
    usersListState.getAuthUser();
  }, []);

  const onSearch = (e: any) => {
    clientsListState.getList(e, typeId);
    setSearch(e);
  };

  function cancel(e: any) {
    console.log(e);
  };

  const onVehicleTypeChange = (v: string) => {
    clientsListState.getList(search, v, deletedCheck);
    setTypeId(v);
  }

  const renderActions = (v: any, record: ListClient) => (
    <Row justify={"space-around"}>
      <Link to={`/client/${record.id}?view=true`}>
        <EyeTwoTone
          style={{fontSize: '2em'}}
        />
      </Link>
      <Link to={`/client/${record.id}`}>
        <EditTwoTone
          style={{fontSize: '2em'}}
        />
      </Link>
      <Popconfirm
        title="Вы уверены, что хотите удалить клиента?"
        onConfirm={async () => {
          await clientsListState.setDelete(record.id);
          await clientsListState.getList()
          message.success('Клиент удалён')
        }}
        onCancel={cancel}
        okText="Да"
        cancelText="Нет"
      >
        <DeleteTwoTone
          style={{fontSize: '2em'}}
        />
      </Popconfirm>
    </Row>
  );

  return (
    <>
      <Col>
        <Row justify={"space-between"}>
          <Title level={4} style={{textAlign: 'left'}}>
            Клиенты
          </Title>
          <FilterOutlined style={{marginRight: 10}} onClick={() => {
            setVisible(!visible)
          }}/>
        </Row>
        <Row justify={"space-between"}>
          <div style={{display: "flex", alignItems: "center"}}>
            <Search
                placeholder="Поиск"
                allowClear
                enterButton="Поиск"
                style={{width: 250}}
                onSearch={onSearch}
            />
            <Checkbox style={{marginLeft: 15}} checked={deletedCheck} onChange={e => {
              setDeletedCheck(e.target.checked);
              clientsListState.getList(search, typeId, e.target.checked);
            }}>
              Показывать удаленных клиентов
            </Checkbox>
          </div>
          {visible && (
            <Select onChange={onVehicleTypeChange} style={{width: 250}}>
              {
                clientsListState.vehicleTypes.map(( e ) => (
                  <Option value={e.id} key={e.id}>{e.name}</Option>
                ))
              }
            </Select>
          )}
        </Row>
        <br/>
        <Table dataSource={clientsListState.clients}>
          <Column title="Фамилия" dataIndex="lastname" key="lastname"/>
          <Column title="Имя" dataIndex="firstname" key="firstname"/>
          <Column title="Отчество" dataIndex="patronymic" key="patronymic"/>
          <Column title="Номер телефона" dataIndex="phone_number" key="phone_number"/>
          <Column title="Тип ТС" dataIndex={["vehicle", "type", "name"]} key="vehicle.type.name"/>
          <Column title="Модель ТС" dataIndex={["vehicle", "model", "name"]} key="vehicle.model.name"/>
          <Column title="Марка ТС" dataIndex={["vehicle", "brand", "name"]} key="vehicle.brand.name"/>
          <Column title="Действия" width={160} render={renderActions}/>
        </Table>
      </Col>
    </>
  )
});
