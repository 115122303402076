import {observer} from "mobx-react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {VehicleTermForm} from "../../components/vehicle-term-form";
import Title from "antd/lib/typography/Title";
import {Card, message, Row} from "antd";
import {DeleteTwoTone, EditTwoTone} from "@ant-design/icons";
import Popconfirm from "antd/es/popconfirm";
import {dictionaryListState} from "../../../../state/dictionary/dictionary-list.state";
import {dictionaryEditState} from "../../../../state/dictionary/dictionary-edit.state";

export const DictionaryEditPage = observer(() => {
  const history = useNavigate();
  const p = useLocation().pathname;
  const { id } = useParams() as any;
  const queryParams = new URLSearchParams(window.location.search);
  const view = !!queryParams.get('view');

  useEffect(() => {
    dictionaryEditState.term = undefined;
    dictionaryEditState.saveResponse = undefined;
    if ( id !== 'add' ) {
      dictionaryEditState.getTerm(dictionaryListState.getDictFromCurrentRoute(p)!.id, id);
    } else {
      dictionaryEditState.term = {
        id: '',
        name: '',
        data: {}
      };
    }
  }, [history, id, dictionaryListState.routeToDictionary]);

  return (
    <>
      <Row justify={"space-between"}>
        <Title level={4} style={{textAlign: 'left'}}>
          { view? 'Просмотр' :'Редактирование'}
        </Title>
        {view && (<Row justify={"space-around"}>
          <Link to={p}>
            <EditTwoTone
                style={{fontSize: '2em'}}
            />
          </Link>
          <Popconfirm
              title="Вы уверены, что хотите удалить запись?"
              onConfirm={ async()=>{
                await dictionaryListState.deleteTerm(p, id);
                await dictionaryListState.getTermsList(dictionaryListState.getDictFromCurrentRoute(p)!.name)
              }}
              okText="Да"
              cancelText="Нет"
          >
            <DeleteTwoTone
                style={{fontSize: '2em', paddingLeft:15}}
            />
          </Popconfirm>
        </Row>)}
      </Row>
        <Card style={{ width: "100%" }} bordered={true}>
          <VehicleTermForm
            location={p}
            view={view}
            onSave={() => {
              history(p.replace(`/${id}`, ''), {replace: true});
              dictionaryListState.getTermsList(dictionaryListState.getDictFromCurrentRoute(p)!.name);
            }}
          />
        </Card>
    </>
  )
});

