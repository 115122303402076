import {makeAutoObservable, runInAction} from "mobx";
import {ClientProvider} from "../../utils/apiClient/clientProvider";
import {DictionaryTerm} from "../../types/dictionary-term";
import {Dictionary} from "../../types/dictionary";
import {message} from "antd";


class DictionaryListState {
  dictionaries: Dictionary[] = [];
  terms: DictionaryTerm[] = [];
  termsCount: number = 0;
  currentPage: number = 1;
  loading: boolean = false;
  routeToDictionary: {
    [key: string]: Dictionary | undefined
  } = {};

  constructor() {
    makeAutoObservable(this);
  }

  setLoading = (v: boolean) => this.loading = v;

  setDictionary(dictionary: DictionaryTerm[]) {
    console.log('setDictionary')
    this.terms = dictionary;
  }

  async getDictionaryList(){
    try {
      this.setLoading(true);
      const res = await ClientProvider.authorized.get(`/dictionary`);
      if ( !res.data.errors ) {
        runInAction(() => {
          this.dictionaries = res.data;
          this.routeToDictionary = {
            '/vehicle-type': this.dictionaries.find( e => e.name === 'vehicle_types' ),
            '/vehicle-model': this.dictionaries.find( e => e.name === 'vehicle_models' ),
            '/vehicle-brand': this.dictionaries.find( e => e.name === 'vehicle_brands' ),
            '/rate-daily': this.dictionaries.find( e => e.name === 'tariff_coefficients_daily' ),
            '/rate-hourly': this.dictionaries.find( e => e.name === 'tariff_coefficients_hourly' ),
          };
        })
      } else {
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }

  public async getTermsListRequest(dictionaryName: string, search?: string, page: number = 1) {
    // const perpage =
    //     (dictionaryName == 'tariff_coefficients_daily' ||
    //     dictionaryName == 'tariff_coefficients_hourly') ? 500 : 100;
    const perpage = 100;
    return ClientProvider.authorized.get(`/dictionary/${dictionaryName}/terms`, {
      params:{
        perpage: perpage,
        parent_unwrap_level: this.getTermUnwrapLevel(dictionaryName),
        search,
        page
      }
    })
  }

  async getTermsList(dictionaryName: string, search?: string, page: number = 1){
    try {
      this.setLoading(true);
      const res = await this.getTermsListRequest(dictionaryName, search, page);
      if ( !res.data.errors ) {
        runInAction(() => {
          this.terms = res.data.data;
          this.termsCount = res.data.count;
          this.currentPage = page;
        })
      } else {
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }

  async deleteTerm(pathname: string, termId: string) {
    try {
      this.setLoading(true);
      const dict = this.getDictFromCurrentRoute(pathname);
      const res = await ClientProvider.authorized.delete(`/dictionary/${dict!.id}/term/${termId}`);
      if ( res.data.errors ) {
        console.error(res);
      }
      message.success("Запись удалена");
    } catch (e) {
      message.error("Не удаётся удалить запись, возможно не удалены все дочерние записи");
    } finally {
      this.setLoading(false);
    }
  }

  getDictFromCurrentRoute(pathname: string) {
    for ( const key in this.routeToDictionary ) {
      if ( pathname.includes(key) ) {
        return this.routeToDictionary[key];
      }
    }
  }

  getTermUnwrapLevel(dictName?: string, dictId?: string) {
    if (dictId) {
      dictName = this.dictionaries.find(e=>e.id === dictId)!.name;
    }
    if ( dictName === 'vehicle_types' ) return 0;
    if ( dictName === 'vehicle_brands' ) return 1;
    if ( dictName === 'vehicle_models' ) return 2;
    if ( dictName === 'tariff_coefficients_daily' ) return 3;
    if ( dictName === 'tariff_coefficients_hourly' ) return 4;
  }
}

export const dictionaryListState: DictionaryListState = new DictionaryListState();
