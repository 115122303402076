import React, {useEffect} from "react";
import {Button, Col, Form, Image, Input, Row} from 'antd';
import {observer} from "mobx-react";
import {loginState} from "../../../state/login/login.state";
import {autorun} from "mobx";
import {systemState} from "../../../state/system.state";
import {useLocation, useNavigate} from "react-router-dom";
import styles from "../../login/pages/index.module.css";
import Title from "antd/lib/typography/Title";

const HomePage = observer(() => {
  const history = useNavigate();
  return (
      <>
        <Col>
          <Title level={5}>
            Главная
          </Title>
          <Image
              className={styles.logo}
              preview={false}
              width={250}
              src="/assets/images/text_logo.png"
          />
        </Col>
      </>
  );
});

export default HomePage;
