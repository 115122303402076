// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".client-form_inputWrite__SST6p{\n    width: 50%;\n}\n.client-form_inputShort__ox08h{\n    width: 17%;\n}\n.client-form_row__2qbus{\n    width: 100%;\n    padding-bottom: 10px;\n}\n.client-form_labelRow__2Xj2E{\n    padding-right: 10px;\n    width: 16%\n}\n.client-form_inputPas__1Owtz{\n    width: 9%;\n    padding-right: 10px;\n}", "",{"version":3,"sources":["webpack://src/views/clients/components/client-form/index.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;AACA;IACI,UAAU;AACd;AACA;IACI,WAAW;IACX,oBAAoB;AACxB;AACA;IACI,mBAAmB;IACnB;AACJ;AACA;IACI,SAAS;IACT,mBAAmB;AACvB","sourcesContent":[".inputWrite{\n    width: 50%;\n}\n.inputShort{\n    width: 17%;\n}\n.row{\n    width: 100%;\n    padding-bottom: 10px;\n}\n.labelRow{\n    padding-right: 10px;\n    width: 16%\n}\n.inputPas{\n    width: 9%;\n    padding-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputWrite": "client-form_inputWrite__SST6p",
	"inputShort": "client-form_inputShort__ox08h",
	"row": "client-form_row__2qbus",
	"labelRow": "client-form_labelRow__2Xj2E",
	"inputPas": "client-form_inputPas__1Owtz"
};
export default ___CSS_LOADER_EXPORT___;
