import {Col, DatePicker, Input, Row} from "antd";
import React, {useEffect, useState} from "react";
import {Observer, observer} from "mobx-react";
import {FilterOutlined} from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import MaskedInput from "antd-mask-input";
import {applicationsListState} from "../../../../state/application/applications-list.state";
import {useLocation, useNavigate} from "react-router-dom";
import {RejectedTable} from "../../components/rejected-table";
import {usersListState} from "../../../../state/users/users-list.state";
import locale from "antd/es/date-picker/locale/ru_RU";

const {Search} = Input;

export const RejectedPage = observer(() => {
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, settoDate] = useState('');
  const [search, setSearch] = useState('');
  const history = useLocation();
  const navigate = useNavigate();
  const {RangePicker} = DatePicker;
  const onFilter = (e: any) => {
    e = e || [null, null];
    setFromDate(e[0] !== null ? e[0].toISOString() : null);
    settoDate(e[1] !== null ? e[1].toISOString() : null);
    applicationsListState.getList(type, fromDate, toDate, search)
  }
  const onSearch = (e: any) => {
    console.log(e);
    setSearch(e);
    applicationsListState.getList(type, fromDate, toDate, e)
  };
  useEffect(() => {
    let t = '';
    if (history.pathname.includes('/operator-applications')) {
      t = 'operator'
    } else if (history.pathname.includes('/under-applications')) {
      t = 'under'
    } else if (history.pathname.includes('/my-applications')) {
      t = 'own'
    } else if (history.pathname.includes('/rejected-applications')) {
      t = 'disapproved'
    }
    setType(t);
    applicationsListState.getList(t);
  }, [history.pathname]);

  useEffect(() => {
    if (applicationsListState.returnedBid) {
      navigate(`/my-applications/${applicationsListState.returnId}`, {replace: true})
    }
  }, [applicationsListState.returnedBid]);

  console.log(applicationsListState.applications.length);

  return (
    <>
      <Col>
        <Row justify={"space-between"}>
          <Title level={4} style={{textAlign: 'left'}}>
            Заявки на регистрацию
          </Title>
          <FilterOutlined style={{marginRight: 10}} onClick={() => {
            setVisible(!visible)
          }}/>
        </Row>
        <Row justify={"space-between"}>
          <Search placeholder="Поиск" onSearch={onSearch} style={{width: 200}}/>
          {visible && (
            <RangePicker locale={locale} format={'DD.MM.YYYY'} onChange={onFilter} allowEmpty={[true, true]}/>
          )}
        </Row>
        <br/>
        <RejectedTable
            applications={applicationsListState.applications}
            isOperator={usersListState.currentUser?.role.includes('OPERATOR')}
            onReturnToWork={(id) => {
              applicationsListState.returnToWork(id);
            }}
        />
      </Col>
    </>
  )
});
