import React, {useContext, useEffect, useRef, useState} from 'react';
import {Form, Image, Input, Table} from 'antd';
import {FormInstance} from 'antd/lib/form';
import {clientEditState} from "../../../../state/clients/client-edit.state";
import {get} from 'lodash';

const EditableContext = React.createContext<FormInstance<any> | null>(null);
const queryParams = new URLSearchParams(window.location.search);

interface Item {
  id: string;
  type: {
    name: string
  };
  brand: {
    name: string
  };
  model: {
    name: string
  };
  price: string;
  acquisition_year: string;
  description: string;
  photos: { url: string }[]
  documents: { url: string }[]
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({index, ...props}) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  image: boolean;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
                                                     title,
                                                     editable,
                                                     children,
                                                     dataIndex,
                                                     record,
                                                     image,
                                                     handleSave,
                                                     ...restProps
                                                   }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<any>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({[dataIndex]: record[dataIndex]});
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({...record, ...values});
    } catch (errInfo) {
      console.log('Не сохранилось:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{margin: 0}}
        name={dataIndex}
        rules={[
          {
            required: title !== 'Год приобретения',
            message: `${title} обязательное.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save}/>
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{paddingRight: 24, minHeight: 30,minWidth: 100}} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  if ( image ) {
    return (
      <td {...restProps}>
        <Image style={{height: '50px'}} src={get(record, Array.isArray(dataIndex) ? dataIndex.join('.') : dataIndex)}/>
      </td>
    )
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
  id: string;
  type: { name: string };
  brand: { name: string };
  model: { name: string };
  price: string;
  acquisition_year: string;
  description: string;
  documents: { url: string }[]
  photos: { url: string }[];
}

interface EditableTableState {
  dataSource: DataType[];
  count: number;
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

export class ClientVehicleTable extends React.Component<EditableTableProps, EditableTableState> {
  columns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string; image?: boolean })[];

  constructor(props: EditableTableProps) {
    super(props);

    this.columns = [
      {
        title: 'Тип ТС',
        dataIndex: ["type", "name"] as any,
        editable: false,
      },
      {
        title: 'Марка ТС',
        dataIndex: ["brand", "name"] as any,
        editable: false,
      },
      {
        title: 'Модель ТС',
        dataIndex: ["model", "name"] as any,
        editable: false,
      },
      {
        title: 'Цена ТС, руб',
        dataIndex: 'price',
        editable: false,
      },
      {
        title: 'Год приобретения',
        dataIndex: 'acquisition_year',
        editable: true,
      },
      {
        title: 'Описание',
        dataIndex: 'description',
        editable: true,
      },
    ];

    if ( clientEditState.client?.vehicle.photos[0] ) {
      this.columns.push({
        title: 'Фото ТС 1',
        dataIndex: ["photos", "0", "url"] as any,
        editable: true,
        image: true
      })
    }
    if ( clientEditState.client?.vehicle.photos[1] ) {
      this.columns.push({
        title: 'Фото ТС 2',
        dataIndex: ["photos", "1", "url"] as any,
        editable: true,
        image: true
      })
    }

    this.state = {
      dataSource: [clientEditState.client!.vehicle],
      count: 1,
    };
  }

  handleSave = (row: DataType) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex(item => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({dataSource: newData});
    clientEditState.client!.vehicle = newData[0] as any;
  };

  render() {
    const {dataSource} = this.state;
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: DataType) => ({
          record,
          editable: col.editable,
          image: col.image,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });
    return (
      <div>
        <Table
          components={components}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={dataSource}
          columns={columns as ColumnTypes}
        />
      </div>
    );
  }
}

//ReactDOM.render(<EditableTable />, mountNode);
