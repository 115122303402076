import {makeAutoObservable} from "mobx";
import {storage} from "../utils/storage";

class SystemState {
  authToken?: string;
  refreshToken?: string;
  email?: string;

  constructor() {
    makeAutoObservable(this);
    this.setAuthToken(storage.authToken || undefined);
    this.setRefreshToken(storage.refreshToken || undefined);
    this.setEmail(storage.email || undefined);
  }

  setAuthToken = (v?: string) => {
    this.authToken = v;
    storage.authToken = v || null;
  }

  setRefreshToken = (v?: string) => {
    this.refreshToken = v;
    storage.refreshToken = v || null;
  }

  setEmail = (v?: string) => {
    this.email = v;
    storage.email = v || null;
  }
}

export const systemState: SystemState = new SystemState();
