import {observer} from "mobx-react";
import {Button, Checkbox, Col, Row, Table} from "antd";
import React from "react";
import {Link} from "react-router-dom";
import Column from "antd/lib/table/Column";
import {Application} from "../../../../types/application";
import {EyeTwoTone} from "@ant-design/icons";

interface Props {
  applications: Application[];
  isOperator?: boolean;
  onReturnToWork: (id: string) => void;
}

export const RejectedTable = observer(({applications, isOperator, onReturnToWork}: Props) => {
  const renderActions = (v: any, record: Application) => (
    <Row justify={"space-around"}>
      <Link to={`/rejected-applications/${record.id}?view=true`}>
        <EyeTwoTone
          style={{fontSize: '2em'}}
        />
      </Link>
      {
        isOperator && record.registration_request.can_be_returned && (
          <Button type="primary" onClick={() => onReturnToWork(record.id)}>Доработать</Button>
        )
      }
    </Row>
  );

  const renderCanBeReturned = (v: boolean) => (
    <Checkbox disabled={true} checked={v}/>
  )

  return (
    <>
      <Col>
        <Table rowKey={"id"} dataSource={applications}>
          <Column title="Id" dataIndex="id" key="id" width={290}/>
          <Column title="Номер телефона пользователя" dataIndex="phone_number" key="phone_number"/>
          <Column title="Дата и время создания" dataIndex="created_at" key="created_at"/>
          <Column title="Статус заявки" dataIndex="status_readable" key="status_readable"/>
          <Column ellipsis={true} title="Причина отклонения" dataIndex={['registration_request', 'disapprove_reason']}
                  key="disapprove_reason"/>
          <Column title="Можно вернуть на доработку" dataIndex={['registration_request', 'can_be_returned']}
                  key="can_be_returned" render={renderCanBeReturned}/>
          <Column title="Действия" render={renderActions}/>
        </Table>
      </Col>
    </>
  );
});
