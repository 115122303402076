import {Col, DatePicker, Row} from "antd";
import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {FilterOutlined} from "@ant-design/icons";
import {ApplicationTable} from "../../components/application-table";
import Title from "antd/lib/typography/Title";
import MaskedInput from "antd-mask-input";
import {applicationsListState} from "../../../../state/application/applications-list.state";
import {useLocation} from "react-router-dom";
import locale from 'antd/es/date-picker/locale/ru_RU';
const { RangePicker } = DatePicker;

export const ListPage = observer(() => {
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState('');
  const [search, setSearch] = useState('');
  const history = useLocation();
  const onFilter = (e: any)=>{
    e = e || [null, null];
    const fromDate =  e[0] !== null ? e[0].toISOString() : null
    const toDate =  e[1] !== null ? e[1].toISOString() : null
    applicationsListState.getList( type, fromDate, toDate, search)
  }
  useEffect(() => {
    let t='';
    if (history.pathname.includes('/operator-applications')) {
      t = 'operator'
    } else if (history.pathname.includes('/under-applications')) {
      t = 'under'
    } else if (history.pathname.includes('/my-applications')) {
      t = 'own'
    } else if (history.pathname.includes('/rejected-applications')) {
      t = 'disapproved'
    }
    setType(t);
    applicationsListState.getList(t);
  }, [history.pathname]);

  return (
    <>
      <Col>
        <Row justify={"space-between"}>
          <Title level={4} style={{textAlign: 'left'}}>
            Заявки на регистрацию
          </Title>
          <FilterOutlined style={{marginRight: 10}} onClick={() => {
            setVisible(!visible)
          }}/>
        </Row>
        {visible && (
          <Row justify={"end"}>
            <RangePicker locale={locale} format={'DD.MM.YYYY'} onChange={onFilter} allowEmpty={[true, true]}/>
          </Row>
        )}
        <br/>
        <ApplicationTable applications={applicationsListState.applications} location={history.pathname}/>
      </Col>
    </>
  )
});
