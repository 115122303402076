// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pages_form__3JFwH{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n.pages_button__2gxhK{\n    display: flex;\n    justify-content: space-between;\n    color: aqua;\n}", "",{"version":3,"sources":["webpack://src/views/login/pages/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,WAAW;AACf","sourcesContent":[".form{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n.button{\n    display: flex;\n    justify-content: space-between;\n    color: aqua;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "pages_form__3JFwH",
	"button": "pages_button__2gxhK"
};
export default ___CSS_LOADER_EXPORT___;
