import {Checkbox, Col, DatePicker, Row} from "antd";
import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {FilterOutlined} from "@ant-design/icons";
import {PolicyTable} from "../components/policy-table";
import Title from "antd/lib/typography/Title";
import {useLocation} from "react-router-dom";
import Search from "antd/lib/input/Search";
import {policyListState} from "../../../state/policy/policy-list.state";
import locale from "antd/es/date-picker/locale/ru_RU";
import moment from "moment";

interface Props {
    demo?: boolean;
}

export const PolicyPage = observer(({demo}: Props) => {
    const history = useLocation();
    const [visible, setVisible] = useState(false);
    const [dob, setDob] = useState('');
    const [search, setSearch] = useState('')
    const [deletedCheck, setDeletedCheck] = useState(false);;

    useEffect(() => {
        policyListState.getList(demo);
    }, [history.pathname]);

    const onFilter = (v: any) => {
        const d = moment(v).toISOString()
        policyListState.getList(demo, search, d, deletedCheck);
        setDob(d);
    }

    const onSearch = (v: any) => {
        policyListState.getList(demo, v, dob, deletedCheck);
        setSearch(v);
    }

    return (
        <>
            <Col>
                <Row justify={"space-between"}>
                    <Title level={4} style={{textAlign: 'left'}}>
                        Полисы
                    </Title>
                    <FilterOutlined style={{marginRight:10}} onClick={()=>{
                        setVisible(!visible)
                    }} />
                </Row>
                <Row justify={"space-between"} >
                    <div style={{display: "flex", alignItems: "center"}}>
                        <Search
                            allowClear
                            enterButton="Поиск"
                            style={{width: 250}}
                            onSearch={onSearch}
                        />
                        <Checkbox style={{marginLeft: 15}} checked={deletedCheck} onChange={e => {
                            setDeletedCheck(e.target.checked);
                            policyListState.getList(demo, search, dob, e.target.checked);
                        }}>
                            Показывать полисы удаленных клиентов
                        </Checkbox>
                    </div>
                    {visible && (
                        <DatePicker locale={locale} onChange={onFilter} />
                    ) }
                </Row>
                <br/>
                <PolicyTable policy={policyListState.policies} />
            </Col>
        </>
    )
});
