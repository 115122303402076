import {observer} from "mobx-react";
import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {UserForm} from "../../components/user-form";
import {userEditState} from "../../../../state/users/user-edit.state";
import {usersListState} from "../../../../state/users/users-list.state";
import Title from "antd/lib/typography/Title";
import {Card, message, Row} from "antd";
import {useQuery} from "react-query";
import {DeleteTwoTone, EditTwoTone} from "@ant-design/icons";
import Popconfirm from "antd/es/popconfirm";

export const UserEditPage = observer(() => {
  const history = useNavigate();
  const {id} = useParams() as any;
  const queryParams = new URLSearchParams(window.location.search);
  const profile = !!queryParams.get('profile');
  const view = !!queryParams.get('view');

  useEffect(() => {
    let user = usersListState.users.find(e => e.id === id);
    userEditState.reset();
    if (profile) {
      userEditState.prefill(usersListState.currentUser!);
    } else if (user) {
      userEditState.prefill(user);
    } else {
      history('/users', {replace: true});
    }
  }, [history, id]);

  return (
    <>
      <Row justify={"space-between"}>
        <Title level={4} style={{textAlign: 'left'}}>
          {view ? 'Просмотр' : 'Редактирование'}
        </Title>
        {view && (<Row justify={"space-around"}>
          <Link to={`/users/${id}`}>
            <EditTwoTone
              style={{fontSize: '2em'}}
            />
          </Link>
          <Popconfirm
            title="Вы уверены, что хотите удалить сотрудника?"
            onConfirm={async () => {
              await usersListState.setDelete(id);
              await usersListState.getList()
              message.success('Сотрудник удалён')
            }}
            okText="Да"
            cancelText="Нет"
          >
            <DeleteTwoTone
              style={{fontSize: '2em', paddingLeft: 15}}
            />
          </Popconfirm>
        </Row>)}
      </Row>
      <Card style={{width: "100%"}} bordered={true}>
        <UserForm
          profile={profile}
          view={view}
          onSave={() => {
            if ( usersListState.currentUser?.role.includes('ADMIN') ) {
              history('/users', {replace: true})
            } else {
              history('/', {replace: true})
            }
            usersListState.getAuthUser();
          }}
        />
      </Card>
    </>
  )
});

