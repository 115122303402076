import {observer} from "mobx-react";
import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {ClientForm} from "../../components/client-form";
import Title from "antd/lib/typography/Title";
import {Card, message, Row} from "antd";
import {DeleteTwoTone, EditTwoTone} from "@ant-design/icons";
import Popconfirm from "antd/es/popconfirm";
import { clientsListState } from "../../../../state/clients/clients-list.state";
import {clientEditState} from "../../../../state/clients/client-edit.state";

export const ClientEditPage = observer(() => {
  const history = useNavigate();
  const { id } = useParams() as any;
  const queryParams = new URLSearchParams(window.location.search);
  const view = !!queryParams.get('view');

  useEffect(() => {
    clientEditState.get(id)
  }, [history, id]);

  return (
    <>
      <Row justify={"space-between"}>
        <Title level={4} style={{textAlign: 'left'}}>
          { view? 'Просмотр' :'Редактирование'}
        </Title>
        {view && (<Row justify={"space-around"}>
          <Link to={`/client/${id}`}>
            <EditTwoTone
                style={{fontSize: '2em'}}
            />
          </Link>
          <Popconfirm
              title="Вы уверены, что хотите удалить клиент?"
              onConfirm={ async()=>{
                await clientsListState.setDelete(id);
                await clientsListState.getList()
                message.success('Клиент удалён')
              }}
              okText="Да"
              cancelText="Нет"
          >
            <DeleteTwoTone
                style={{fontSize: '2em', paddingLeft:15}}
            />
          </Popconfirm>
        </Row>)}
      </Row>
        <Card style={{ width: "100%" }} bordered={true}>
          <ClientForm
            view={view}
            onSave={() => history('/clients', {replace: true})}
          />
        </Card>
    </>
  )
});

