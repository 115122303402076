import {observer} from "mobx-react";
import {Button, Col, Empty, Input, InputNumber, message, Popconfirm, Row, Table} from "antd";
import {localizationState} from "../../../../state/localization/localization.state";
import {DeleteTwoTone, EditTwoTone, SaveTwoTone} from "@ant-design/icons";
import React, {useState} from "react";
import prompt from 'antd-prompt';

const { Search, TextArea } = Input;
const {Column} = Table;

interface Props {
  locale: string;
}

export const LocalizationTable = observer(({locale}: Props) => {
  const [editedRow, setEditedRow] = useState();
  const [editedField, setEditedField] = useState('');
  const [editedValue, setEditedValue] = useState('');
  const [search, setSearch] = useState('');

  if ( !localizationState.localizations ) {
    return <Empty description={<span>Загрузка...</span>}/>;
  }
  let formattedData = localizationState.localizations[locale]
    .map(e => {
      const children = e.items.map( (i, idx) => {
        return {
          key: `${e.block}_${idx}`,
          field: i.code,
          value: i.text,
          parentKey: e.block,
        }
      });
      children.push({
        key: `${e.block}_add`,
        field: '',
        value: '',
        parentKey: e.block
      });
      return {
        key: e.block + '_block',
        field: e.block,
        value: '',
        children
      }
    });
  if ( search !== '' ) {
    formattedData = formattedData.filter(e => {
      return e.field.startsWith(search);
    });
  }

  const recordIsBlock = (record: any) => record.key.endsWith('_block');

  const addBlockPrompt = async () => {
    try {
      const blockCode = await prompt({
        title: "Укажите код нового блока",
        placeholder: "Код блока",
        rules: [
          {
            required: true,
            message: "Нужно ввести код"
          }
        ],
        modalProps: {
          width: '40%'
        }
      });
      if ( !blockCode ) {
        return;
      }
      if ( formattedData.find( e => e.field === blockCode) ) {
        return message.error('Такой блок уже есть');
      }
      await localizationState.updateLocalization(locale, {
        operation: "block_add",
        data: {
          block: blockCode
        }
      });
      localizationState.getLocalizations();
    } catch (e) {
      //message.error('Укажите название нового блока');
    }
  }

  const addFieldPrompt = async (record: any) => {
    try {
      const fieldCode = await prompt({
        title: "Укажите код нового поля",
        placeholder: "Код поля",
        rules: [
          {
            required: true,
            message: "Нужно ввести код"
          }
        ],
        modalProps: {
          width: '40%'
        }
      });
      if ( !fieldCode ) {
        return;
      }
      if ( formattedData.find( e => e.key === `${record.parentKey}_block`)!.children.find( e => e.field === fieldCode) ) {
        return message.error('Такое поле уже есть');
      }
      await localizationState.updateLocalization(locale,{
        operation: "field_add",
        data: {
          block: record.parentKey,
          code: fieldCode
        }
      });
      localizationState.getLocalizations();
    } catch (e) {
      //message.error('Укажите название нового блока');
    }
  }

  const onSave = async (record: any) => {
    setEditedRow(undefined);
    let operation: string;
    const data: any = {};
    if ( recordIsBlock(record) ) {
      operation = 'block_update';
      data.block = record.field;
      data.newBlock = editedField;
    } else {
      operation = 'field_update'
      data.block = record.parentKey;
      data.code = record.field;
      data.newCode = editedField
      data.newText = editedValue;
    }

    setEditedValue('');
    setEditedField('');

    await localizationState.updateLocalization(locale,{
      operation,
      data: data
    });
    localizationState.getLocalizations();
  }

  const onDelete = async (record: any) => {
    let operation: string;
    const data: any = {};
    if ( recordIsBlock(record) ) {
      operation = 'block_delete';
      data.block = record.field;
    } else {
      operation = 'field_delete';
      data.block = record.parentKey;
      data.code = record.field;
    }
    await localizationState.updateLocalization(locale, {
      operation,
      data
    });
    localizationState.getLocalizations();
  };

  const renderField = (v: any, record: any) => {
    if ( record.key !== editedRow ) {
      return <span style={{fontWeight: recordIsBlock(record) ? 700: 400}}>{record.field}</span>
    }

    return (
      <Input
        style={{width: '80%'}}
        value={editedField}
        onChange={e => setEditedField(e.target.value)}
        bordered={false}
      />
    );
  }

  const renderValue = (v: any, record: any) => {
    if (recordIsBlock(record)){
      return null;
    }
    if ( record.key !== editedRow ) {
      return <span>{record.value}</span>
    }

    return (
      <TextArea
        style={{width: '95%', marginTop: "20px", height: 'auto', minHeight: '275px', backgroundColor: '#efefef'}}
        value={editedValue}
        onChange={e => setEditedValue(e.target.value)}
        bordered={true}
      />
    );
  }

  const renderActions = (v: any, record: any) => {
    const components: JSX.Element[] = [];
    if ( record.key.endsWith('_add') ) {
      return (
        <Button type="primary" onClick={() => addFieldPrompt(record)}>
          Добавить
        </Button>
      );
    }
    if ( record.key === editedRow ) {
      components.push(
        <SaveTwoTone
          style={{fontSize:'2em'}}
          onClick={() => onSave(record)}
        />
      )
    } else {
      components.push(
        <EditTwoTone
          onClick={() => {
            setEditedRow(record.key);
            setEditedField(record.field);
            if ( !recordIsBlock(record) ) {
              setEditedValue(record.value);
            } else {
              setEditedValue('');
            }
          }}
          style={{fontSize:'2em'}}
        />
      );
      components.push(
        <Popconfirm
          title="Удалить этот блок/поле?"
          onConfirm={() => onDelete(record)}
          onCancel={() => {}}
          okText="Да"
          cancelText="Нет"
        >
          <DeleteTwoTone
            style={{fontSize:'2em'}}
          />
        </Popconfirm>
      );
    }

    return (
      <div style={{display: "flex", justifyContent: "space-between"}}>
        {components}
      </div>
    );
  };

  return (
    <>
      <Row>
        <Col span={4}>
          <Search placeholder="Поиск" onChange={(e) => setSearch(e.target.value)} style={{ width: 300 }} />
        </Col>
        <Col offset={17} span={3}>
          <Button type="primary" onClick={addBlockPrompt}>
            Добавить блок
          </Button>
        </Col>
      </Row>
      <br/>
      <br/>
      <Table dataSource={formattedData}>
        <Column title={'Блок/Поле'} render={renderField} width={'28%'}/>
        <Column title={'Перевод'} render={renderValue} width={'63%'}/>
        <Column title={'Действия'} render={renderActions} width={'9%'}/>
      </Table>
    </>
  );
});
