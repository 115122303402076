import {makeAutoObservable, runInAction} from "mobx";
import {ClientProvider} from "../../utils/apiClient/clientProvider";
import {DictionaryTerm} from "../../types/dictionary-term";
import {dictionaryListState} from "./dictionary-list.state";
import { get } from 'lodash';

interface EditFormTerms {
  types: DictionaryTerm[];
  brands: DictionaryTerm[]
}

export class DictionaryEditState {
  loading: boolean = false;
  saveResponse?: DictionaryTerm;

  term?: DictionaryTerm;
  vehicleEditFormTerms: EditFormTerms = {
    types: [],
    brands: []
  }

  setLoading = (v: boolean) => this.loading = v;

  constructor() {
    makeAutoObservable(this);
  }

  async trySave(pathName: string) {
    try {
      let res: any;
      this.setLoading(true);
      const dict = dictionaryListState.getDictFromCurrentRoute(pathName)
      const data = {
        name: this.term?.name,
        parent_id: ( this.term as any)['parent.id'],
        data: {
          full_name: ( this.term as any)['data.full_name'],
          tariff: (this.term as any)['data.tariff'],
          coefficient: parseFloat((this.term as any)['data.coefficient'])
        }
      };
      if (this.term?.id) {
        res = await ClientProvider.authorized.put(`/dictionary/${dict!.id}/term/${this.term?.id}`, data);
      } else {
        res = await ClientProvider.authorized.post(`/dictionary/${dict!.id}/term`, data);
      }
      if (!res.data.errors) {
        runInAction(() => {
          this.saveResponse = res.data;
        });
      } else {
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }

  async getTerm(dictId: string, id: string) {
    try {
      this.setLoading(true);
      const res = await ClientProvider.authorized.get(`/dictionary/${dictId}/term/${id}`, {
        params: {
          parent_unwrap_level: dictionaryListState.getTermUnwrapLevel(undefined, dictId)
        }
      });
      if (!res.data.errors) {
        runInAction(() => {
          console.log('res.data', res.data);
          this.term = {} as DictionaryTerm;
          for (const key in res.data) {
            (this.term as any)[key] = res.data[key];
          }
        })
      } else {
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }

  async getParentsForEditForm(dictionaryName: string, type: keyof EditFormTerms, parentId?: string){
    try {
      this.setLoading(true);
      if ( type === "types" ) {
        this.vehicleEditFormTerms["brands"] = [];
      }
      const res = await ClientProvider.authorized.get(`/dictionary/${dictionaryName}/terms`, {
        params:{
          page: 1,
          perpage: 10000,
          parent_id: parentId
        }
      });
      if ( !res.data.errors ) {
        runInAction(() => {
          this.vehicleEditFormTerms[type] = res.data.data;
        })
      } else {
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }
}


export const dictionaryEditState: DictionaryEditState = new DictionaryEditState();
