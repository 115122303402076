import {makeAutoObservable, runInAction} from "mobx";
import {ClientProvider} from "../../utils/apiClient/clientProvider";
import {Application} from "../../types/application";
import moment from "moment";
import {applicationStatus} from "../../utils/application-statuses";
import {message} from "antd";

class ApplicationsListState {
  applications: Application[] = [];
  loading: boolean = false;
  public returnedBid: boolean = false;
  public returnId: string = '';
  fromDate: string = '';
  toDate: string = '';
  search = '';

  constructor() {
    makeAutoObservable(this);
  }

  setLoading = (v: boolean) => this.loading = v;

  async startBid(id?: string) {
    try {
      this.setLoading(true);
      const res = await ClientProvider.authorized.put(`/bid/${id}/start`);
      message.success("Заявка взята в работу");
    } catch (e) {
      message.error("Ошибка");
    } finally {
      this.setLoading(false);
    }
  }

  async startBidUnderwriter(id?: string) {
    try {
      this.setLoading(true);
      const res = await ClientProvider.authorized.put(`/bid/${id}/start-underwriter`);
      message.success("Заявка взята в работу");
    } catch (e) {
      message.error("Ошибка");
    } finally {
      this.setLoading(false);
    }
  }

  async getList(type?: string, fromDate?: string, toDate?:string, search?:string) {
    try {
      this.returnedBid = false;
      this.setLoading(true);
      const res = await ClientProvider.authorized.get(`/bid`, {
        params: {
          type: type,
          fromDate: fromDate,
          toDate: toDate,
          search: search
        }
      });
      if (!res.data.errors) {
        runInAction(() => {
          this.applications = res.data.map((e: any) => {
            return {
              ...e,
              created_at: moment(e.created_at).format('DD.MM.YYYY HH:mm'),
              status_readable: applicationStatus[e.status]
            }
          });
        })
      } else {
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }

  async returnToWork(id: string) {
    try {
      this.setLoading(true);
      const res = await ClientProvider.authorized.put(`/bid/${id}/to-work`);
      if (res.status === 200) {
        message.success("Заявка возвращена");
        runInAction(() => {
          this.returnedBid = true;
          this.returnId = id;
        })
      } else {
        message.error("Ошибка. Возможно заявка находится не в том статусе");
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }
}

export const applicationsListState: ApplicationsListState = new ApplicationsListState();
