import {makeAutoObservable, runInAction} from "mobx";
import {CognitoUser, CognitoUserPool} from 'amazon-cognito-identity-js';
import {message} from "antd";
import {useNavigate} from "react-router-dom";

class RestoreState {
    private _email: string = '';
    private _loading: boolean = false;
    private _code: string = '';
    private _password: string = '';
    private _repeatPassword: string = '';

    get loading(): boolean {
        return this._loading;
    }

    set loading(value: boolean) {
        this._loading = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get code(): string {
        return this._code;
    }

    set code(value: string) {
        this._code = value;
    }

    get password(): string {
        return this._password;
    }

    set password(value: string) {
        this._password = value;
    }

    get repeatPassword(): string {
        return this._repeatPassword;
    }

    set repeatPassword(value: string) {
        this._repeatPassword = value;
    }

    constructor() {
        makeAutoObservable(this);
    }

    async restoreReset(onSuccess: () => void) {
        runInAction(() => {
            try {
                this._loading = true;

                const userPool = new CognitoUserPool({
                    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
                    ClientId: process.env.REACT_APP_COGNITO_APP_ID!
                });

                const cognitoUser = new CognitoUser({
                    Username: this._email,
                    Pool: userPool
                });
                cognitoUser.confirmPassword(this._code, this._password, {
                    onFailure(err) {
                        message.error('Ошибка установки нового пароля');
                    },
                    onSuccess() {
                        message.success('Пароль успешно изменён');
                        onSuccess();
                    },
                })
            } catch (e) {
                console.log(e);
                this._loading = false;
            }
        });
    }
}

export const restoreState: RestoreState = new RestoreState();
