import {observer} from "mobx-react";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import Title from "antd/lib/typography/Title";
import {Card, Row} from "antd";
import {PolicyForm} from "../components/policy-form";
import {policyViewState} from "../../../state/policy/policy-view.state";

export const PolicyViewPage = observer(() => {
  const history = useNavigate();
  const { id } = useParams() as any;

  useEffect(() => {
    policyViewState.get(id)
  }, [id, history]);

  return (
    <>
      <Row justify={"space-between"}>
        <Title level={4} style={{textAlign: 'left'}}>
          Просмотр
        </Title>
      </Row>
        <Card style={{ width: "100%" }} bordered={true}>
          <PolicyForm/>
        </Card>
    </>
  )
});

