import {makeAutoObservable, runInAction} from "mobx";
import {ClientProvider} from "../../utils/apiClient/clientProvider";
import {User} from "../../types/user";

class UsersListState {
  users: User[] = [];
  currentUser?: User;
  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading = (v: boolean) => this.loading = v;

  setUsers(users: User[]) {
    this.users = users;
  }

  async getList(){
    try {
      this.setLoading(true);
      const res = await ClientProvider.authorized.get('/service-user', {
        params:{
          role: 'OPERATOR,UNDER,ADMIN',
          page: 1,
          perpage: 100
        }
      });
      if ( !res.data.errors ) {

       res.data.forEach((e: any) => {
          e.name = `${e.firstname} ${e.lastname} ${e.patronymic}`;
        })
        runInAction(() => {
          this.users = res.data;
        })
      } else {
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }

  async getAuthUser(){
    try {
      this.setLoading(true);
      const res = await ClientProvider.authorized.get('/user/info');
      if ( !res.data.errors ) {
        const data = res.data
        res.data.name = `${data.firstname} ${data.lastname} ${data.patronymic}`
        runInAction(() => {
          this.currentUser = res.data;
        })
      } else {
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }

  async setDelete(id: string) {
    try {
      this.setLoading(true);
      const res = await ClientProvider.authorized.delete(`/user/${id}`);
      if ( res.data.errors ) {
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }

  async activationUser(id: string) {
    try {
      this.setLoading(true);
      const res = await ClientProvider.authorized.put(`/user/${id}`);
      if ( res.data.errors ) {
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }

  async setActive(id: string, value: boolean) {
    try {
      this.setLoading(true);
      const res = await ClientProvider.authorized.put(`/user/${id}/active`, {
        isActive: value
      });
      if ( res.data.errors ) {
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }
}

export const usersListState: UsersListState = new UsersListState();
