import {Button, Col, Dropdown, Menu, message, Row, Switch, Table} from "antd";
import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {Link, Route, useNavigate} from "react-router-dom";
import {DeleteTwoTone, EditTwoTone, EyeTwoTone, ApiTwoTone} from "@ant-design/icons";
import {usersListState} from "../../../../state/users/users-list.state";
import {User} from "../../../../types/user";
import Popconfirm from "antd/es/popconfirm";

const {Column} = Table;

export const UsersListPage = observer(() => {
    const [visible, setVisible] = useState(false);
    const route = useNavigate();
    useEffect(() => {
        usersListState.getList();
        usersListState.getAuthUser();
    }, []);

    function cancel(e: any) {
        console.log(e);
    };

    const renderActions = (v: any, record: User) => (
        <Row justify={"space-around"}>
            <Link to={`/users/${record.id}?view=true`}>
                <EyeTwoTone
                    style={{fontSize: '2em'}}
                />
            </Link>
            <Link to={`/users/${record.id}`}>
                <EditTwoTone
                    style={{fontSize: '2em'}}
                />
            </Link>
            {!record.is_deleted &&
            <Popconfirm
                title="Вы уверены, что хотите деактивировать сотрудника?"
                onConfirm={async () => {
                    await usersListState.setDelete(record.id);
                    await usersListState.getList()
                    message.success('Сотрудник деактивирован')
                }}
                onCancel={cancel}
                okText="Да"
                cancelText="Нет"
            >
                <DeleteTwoTone
                    style={{fontSize: '2em'}}
                />
            </Popconfirm>
            }

            {record.is_deleted &&
            <Popconfirm
                title="Вы уверены, что хотите активировать сотрудника?"
                onConfirm={async () => {
                    await usersListState.activationUser(record.id);
                    await usersListState.getList()
                    message.success('Сотрудник активирован')
                }}
                onCancel={cancel}
                okText="Да"
                cancelText="Нет"
            >
                <ApiTwoTone
                    style={{fontSize: '2em'}}
                />
            </Popconfirm>
            }
        </Row>
    );

    return (
        <>
            <Col>
                <Row>
                    <Col offset={22}>
                        <Link to={'/users/add'}>
                            <Button type="primary">
                                Добавить
                            </Button>
                        </Link>
                    </Col>
                </Row>
                <br/>
                <Table dataSource={usersListState.users}>
                    <Column title="Id" dataIndex="id" key="id" width={370}/>
                    <Column title="ФИО" dataIndex="name" key="name"/>
                    <Column title="Логин" dataIndex="email" key="email"/>
                    <Column title="Действия" width={160} render={renderActions}/>
                </Table>
            </Col>
        </>
    )
});
