// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".forget_form__a9Fsw{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n.forget_button__2oa5i{\n    display: flex;\n    justify-content: center;\n    color: aqua;\n}", "",{"version":3,"sources":["webpack://src/views/login/pages/forget/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,WAAW;AACf","sourcesContent":[".form{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n.button{\n    display: flex;\n    justify-content: center;\n    color: aqua;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "forget_form__a9Fsw",
	"button": "forget_button__2oa5i"
};
export default ___CSS_LOADER_EXPORT___;
